import { useEffect, useState, type FC } from 'react'
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01'
import type { Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import useMediaQuery from '@mui/material/useMediaQuery'
import { alpha } from '@mui/material/styles'
import { AccountButton } from '../account-button'
import i18n from '@/locales/i18n'
import { ZendeskAPI } from '@/utils/zendeskConfig'
import { HelpNabuButton } from '../help-nabu'

// Constants for layout dimensions
const TOP_NAV_HEIGHT = 64
const SIDE_NAV_WIDTH = 240

interface TopNavProps {
  onMobileNavOpen?: () => void
}

/**
 * TopNav component - renders the top navigation bar of the application.
 */
export const TopNav: FC<TopNavProps> = (props) => {
  const { onMobileNavOpen, ...other } = props
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  // Determine the language for Zendesk
  const lang = i18n?.language
    ? (i18n.language.toLowerCase() as 'en' | 'fr')
    : 'fr'

  // Set Zendesk locale based on the current language
  useEffect(() => {
    ZendeskAPI('messenger:set', 'locale', lang === 'fr' ? 'fr' : 'en-gb')
  }, [lang])

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.8),
        position: 'sticky',
        left: {
          lg: `${SIDE_NAV_WIDTH}px`,
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2,
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          {/*<SearchButton />*/}
        </Stack>
        <Stack alignItems="center" direction="row" spacing={2}>
          {/*<LanguageSwitch />*/}
          <Box>
            <HelpNabuButton />
          </Box>
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  )
}
