import type { RouteObject } from 'react-router'
import Error404Page from '@/pages/Error404'
import { authRoutes } from './auth'
import { dashboardRoutes } from './dashboard'
import { backofficeRoutes } from './backoffice'
import { managementConsoleRoutes } from './managementConsole'

export const routes: RouteObject[] = [
  ...authRoutes,
  ...dashboardRoutes,
  ...managementConsoleRoutes,
  ...backofficeRoutes,
  {
    path: '*',
    element: <Error404Page />,
  },
]
