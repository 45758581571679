import type { FC } from 'react'
import { Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

interface ErrorProps {
  statusCode: number
  title: string
}

export const Error: FC<ErrorProps> = (props) => {
  const { statusCode, title } = props
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const text = `${statusCode}: ${title}`

  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          py: '80px',
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 6,
            }}
          >
            <Typography align="center" variant={mdUp ? 'h1' : 'h4'}>
              {text}
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  )
}
