import type { FC, ReactNode } from 'react'
import { Theme, styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import type { NavColor } from '@/types/settings'
import type { Section } from '../config'
import { MobileNav } from '../mobile-nav'
import { SideNav } from './side-nav'
import { TopNav } from './top-nav'
import { useMobileNav } from './use-mobile-nav'

const SIDE_NAV_WIDTH = 250
const SIDE_NAV_FOLDED_WIDTH = 64

const VerticalLayoutRoot = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
}))

interface VerticalLayoutProps {
  children?: ReactNode
  navColor?: NavColor
  sections?: Section[]
  isFullscreen?: boolean
}

export const VerticalLayout: FC<VerticalLayoutProps> = (props) => {
  const { children, sections, navColor, isFullscreen } = props
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  const mobileNav = useMobileNav()

  return (
    <>
      {!isFullscreen && <TopNav onMobileNavOpen={mobileNav.handleOpen} />}
      {lgUp && (
        <SideNav color={navColor} sections={sections} isFolded={isFullscreen} />
      )}
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}

      <VerticalLayoutRoot
        style={{
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          width: '100%',
        }}
        sx={(theme) => ({
          [theme.breakpoints.up('lg')]: {
            paddingLeft: `${
              !isFullscreen ? SIDE_NAV_WIDTH : SIDE_NAV_FOLDED_WIDTH
            }px`,
          },
        })}
      >
        <div
          style={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {children}
        </div>
      </VerticalLayoutRoot>
    </>
  )
}
