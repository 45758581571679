import type { FC } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { paths } from '@/paths'
import useTranslate from '@/hooks/use-translate'

export const ErrorUnknown: FC = () => {
  const { t: tCommon } = useTranslate('common')
  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          py: '80px',
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 6,
            }}
          >
            <Box
              alt="Internal server error"
              component="img"
              src="/assets/errors/error-500.png"
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 400,
              }}
            />
          </Box>
          <Typography align="center" variant={'h4'}>
            {tCommon('titleErrorPage')}
          </Typography>
          <Typography align="center" color="text.secondary" sx={{ mt: 0.5 }}>
            {tCommon('messageErrorPage')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6,
            }}
          >
            {/*there was RouterLink here before but it wasn't reloading the page...*/}
            <Button onClick={() => window.location.reload()} href={paths.index}>
              {tCommon('backToHome')}
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default ErrorUnknown
