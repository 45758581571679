import React, { useState, useEffect, ComponentType } from 'react'
import packageJson from '../package.json'
import moment from 'moment'

const buildDateGreaterThan = (
  latestDate: string,
  currentDate: string
): boolean => {
  const momLatestDateTime = moment(latestDate)
  const momCurrentDateTime = moment(currentDate)

  console.log('Date build ' + momLatestDateTime + ' ' + momCurrentDateTime)

  return momLatestDateTime.isAfter(momCurrentDateTime)
}

interface MetaData {
  buildDate: string
}

function withClearCache<T extends JSX.IntrinsicAttributes>(
  Component: ComponentType<T>
) {
  const ClearCacheComponent: React.FC<T> = (props) => {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false)

    useEffect(() => {
      fetch('/meta.json')
        .then((response) => response.json())
        .then((meta: MetaData) => {
          const latestVersionDate = meta.buildDate
          const currentVersionDate = packageJson.buildDate

          console.log(
            'Date build ' + latestVersionDate + ' <=> ' + currentVersionDate
          )

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
          )
          if (shouldForceRefresh) {
            console.log('Demande clean cache')
            setIsLatestBuildDate(false)
            refreshCacheAndReload()
          } else {
            setIsLatestBuildDate(true)
          }
        })
    }, [])

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        if (
          process.env.NODE_ENV === 'production' ||
          process.env.NODE_ENV === 'staging'
        ) {
          return
        }

        caches.keys().then((names) => {
          for (const name of names) {
            if (name.includes('static')) {
              console.log('delete ' + name)
              setTimeout(() => {
                console.log('deleted --> ' + name)
                caches.delete(name)
              }, 3000)
            } else {
              console.log('Not delete ' + name)
            }
          }
        })
        window.location.reload()
      }
    }

    return (
      <React.Fragment>
        {isLatestBuildDate ? <Component {...props} /> : null}
      </React.Fragment>
    )
  }

  return ClearCacheComponent
}

export default withClearCache
