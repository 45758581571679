import { ComponentType } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { Error404 } from '@/pages/Error404'
import { Error401 } from '@/pages/Error401'
import { Error500 } from '@/pages/Error500'
import ErrorUnknown from '@/pages/ErrorUnknown';

interface FallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

export const CustomErrorFallbacks: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {

  if (error.message.includes("401")) {
    return (<Error401 />)
  }
  if (error.message.includes("404")) {
    return (<Error404 />)
  }
  if (error.message.includes("500")) {
    return (<Error500 />)
  }

  return (<ErrorUnknown />);
}

type WithErrorBoundaryOptions = {
  onError?: (error: Error, info: { componentStack: string }) => void;
  onReset?: () => void;
};

export function wrapWithErrorBoundary<P extends object>(
  Component: ComponentType<any>,
  options: WithErrorBoundaryOptions = {}
): ComponentType<any> {
  return (props: P) => {
    const WrappedComponent = withErrorBoundary(Component, {
      FallbackComponent: CustomErrorFallbacks,
      ...options
    });

    return <WrappedComponent {...props} />;
  };
}