import useTranslate from '@/hooks/use-translate'

export default function useUserLanguage() {
  const { t: tCommon } = useTranslate('common')

  const languageEnum = [
    { label: tCommon('french'), value: 'fr' },
    { label: tCommon('english'), value: 'en' },
  ]

  return languageEnum
}
