import type { FC, ReactNode } from 'react'
import { Error } from '@/components/Error'
import { useAuth } from '@/hooks/use-auth'
import { Issuer } from '@/types/auth'

interface IssuerGuardProps {
  children: ReactNode
  issuer: Issuer
}

// This guard protects an auth page from being loaded when using a different issuer.
export const IssuerGuard: FC<IssuerGuardProps> = (props) => {
  const { children, issuer: expectedIssuer } = props
  const { issuer } = useAuth()

  if (expectedIssuer !== issuer) {
    return (
      <Error
        statusCode={400}
        title={`Issuer mismatch, currently using ${issuer}`}
      />
    )
  }

  return <>{children}</>
}
