export const determineDeclarationType = (
  optionName: string
): 'import' | 'export' | 'transit' => {
  // Convertir le nom en minuscules pour la comparaison insensible à la casse
  const lowercaseName = optionName.toLowerCase()

  // Vérifier si le nom contient "import" ou "export" et retourner le résultat correspondant
  if (lowercaseName.includes('import')) {
    return 'import'
  } else if (lowercaseName.includes('export')) {
    return 'export'
  } else if (lowercaseName.includes('transit')) {
    return 'transit'
  } else {
    // Si le nom ne contient ni "import" ni "export", retourner une valeur par défaut
    throw new Error(
      'Le nom de l\'option ne contient ni "import" ni "export" ni "transit"'
    )
  }
}
