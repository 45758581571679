/* eslint-disable prettier/prettier */

export const tokens = {
  common: {
    welcome: 'common.welcome',
    welcomeLogin: 'common.welcomeLogin',
    welcomeLoginText: 'common.welcomeLoginText',
    login: 'common.login',
    loginError: 'common.loginError',
    emailSendError: 'common.emailSendError',
    returnToLoginPage: 'common.returnToLoginPage',
    logout: 'common.logout',
    noAccount: 'common.noAccount',
    questionForgotPassword: 'common.questionForgotPassword',
    forgotPasswordText: 'common.forgotPasswordText',
    forgotPassword: 'common.forgotPassword',
    enterNewPassword: 'common.enterNewPassword',
    passwordConfirmation: 'common.passwordConfirmation',
    oldPasswordRequired: 'common.oldPasswordRequired',
    newPasswordRequired: 'common.newPasswordRequired',
    warningRulesForNewPassword: 'common.warningRulesForNewPassword',
    emailSent: 'common.emailSent',
    send: 'common.send',
    contactUs: 'common.contactUs',
    confirm: 'common.confirm',
    validate: 'common.validate',
    delete: 'common.delete',
    cancel: 'common.cancel',
    errorRenameFlowName: 'common.errorRenameFlowName',
    continue: 'common.continue',
    next: 'common.next',
    back: 'common.back',
    close: 'common.close',
    create: 'common.create',
    upload: 'common.upload',
    sentDocuments: 'common.sentDocuments',
    sentFolders: 'common.sentFolders',
    tableConsolidated: 'common.tableConsolidated',
    tableNotConsolidated: 'common.tableNotConsolidated',
    numberofLinesSent: 'common.numberofLinesSent',
    add: 'common.add',
    languageChanged: 'common.languageChanged',
    manager: 'common.manager',
    guest: 'common.guest',
    admin: 'common.admin',
    user: 'common.user',
    superadmin: 'common.superadmin',
    mail: 'common.mail',
    email: 'common.email',
    mailHook: 'common.mailHook',
    regenerateMailHook: 'common.regenerateMailHook',
    mailHookDescription: 'common.mailHookDescription',
    copy: 'common.copy',
    name: 'common.name',
    role: 'common.role',
    date: 'common.date',
    classification: 'common.classification',
    member: 'common.member',
    members: 'common.members',
    administrator: 'common.administrator',
    administrators: 'common.administrators',
    sendInvite: 'common.sendInvite',
    inviteMember: 'common.inviteMember',
    inviteMembers: 'common.inviteMembers',
    inviteAdministrator: 'common.inviteAdministrator',
    inviteAdministrators: 'common.inviteAdministrators',
    inviteSomeAdministrators: 'common.inviteSomeAdministrators',
    atLeastOneInvitedPeople: 'common.atLeastOneInvitedPeople',
    maxOneInvitedPeople: 'common.maxOneInvitedPeople',
    sendInvitation: 'common.sendInvitation',
    deleteInvitation: 'common.deleteInvitation',
    socialName: 'common.socialName',
    registrationNumber: 'common.registrationNumber',
    country: 'common.country',
    organization: 'common.organization',
    organizationAndEstablishments: 'common.organizationAndEstablishments',
    somethingWentWrong: 'common.somethingWentWrong',
    general: 'common.general',
    lastName: 'common.lastName',
    firstName: 'common.firstName',
    function: 'common.function',
    preferences: 'common.preferences',
    password: 'common.password',
    position: 'common.position',
    language: 'common.language',
    help: 'common.help',
    active: 'common.active',
    group: 'common.group',
    groups: 'common.groups',
    collaborators: 'common.collaborators',
    update: 'common.update',
    parameters: 'common.parameters',
    external: 'common.external',
    validEmail: 'common.validEmail',
    required: 'common.required',
    emailRequired: 'common.emailRequired',
    passwordRequired: 'common.passwordRequired',
    passwordConfirmationRequired: 'common.passwordConfirmationRequired',
    matchPasswordsRequired: 'common.matchPasswordsRequired',
    firstNameRequired: 'common.firstNameRequired',
    lastNameRequired: 'common.lastNameRequired',
    roleRequired: 'common.roleRequired',
    languageRequired: 'common.languageRequired',
    organizationRequired: 'common.organizationRequired',
    groupRequired: 'common.groupRequired',
    socialNameRequired: 'common.socialNameRequired',
    registrationNumberRequired: 'common.registrationNumberRequired',
    countryRequired: 'common.countryRequired',
    affiliatedGroup: 'common.affiliatedGroup',
    share: 'common.share',
    registeredCustomsRepresentative: 'common.registeredCustomsRepresentative',
    customsManager: 'common.customsManager',
    customsTeamManager: 'common.customsTeamManager',
    informationSystemsManager: 'common.informationSystemsManager',
    nabuAdministrator: 'common.nabuAdministrator',
    french: 'common.french',
    english: 'common.english',
    basicInformation: 'common.basicInformation',
    personalInformation: 'common.personalInformation',
    companyInformation: 'common.companyInformation',
    organizationAndGroups: 'common.organizationAndGroups',
    establishmentsAndGroups: 'common.establishmentsAndGroups',
    settings: 'common.settings',
    save: 'common.save',
    changePassword: 'common.changePassword',
    oldPassword: 'common.oldPassword',
    newPassword: 'common.newPassword',
    passwordChangeSuccess: 'common.passwordChangeSuccess',
    fileName: 'common.fileName',
    generalInformation: 'common.generalInformation',
    offices: 'common.offices',
    edit: 'common.edit',
    accessInformations: 'common.accessInformations',
    deleteAccount: 'common.deleteAccount',
    deleteAccountWarning: 'common.deleteAccountWarning',
    client: 'common.client',
    status: 'common.status',
    actions: 'common.actions',
    option10: 'common.option10',
    option20: 'common.option20',
    option50: 'common.option50',
    searchByCaseName: 'common.searchByCaseName',
    all: 'common.all',
    noOptions: 'common.noOptions',
    pending: 'common.pending',
    from: 'common.from',
    to: 'common.to',
    view: 'common.view',
    folder: 'common.folder',
    declaration: 'common.declaration',
    customDeclaration: 'common.customDeclaration',
    billOfLading: 'common.billOfLading',
    exported: 'common.exported',
    toTreat: 'common.toTreat',
    archived: 'common.archived',
    inProgress: 'common.inProgress',
    category: 'common.category',
    searchByName: 'common.searchByName',
    layerName: 'common.layerName',
    flowName: 'common.flowName',
    flowSearchPlaceholder: 'common.flowSearchPlaceholder',
    flowTabName: 'common.flowTabName',
    flowTabType: 'common.flowTabType',
    declarationType: 'common.declarationType',
    flowTabExporter: 'common.flowTabExporter',
    flowTabCreatedBy: 'common.flowTabCreatedBy',
    flowTabDate: 'common.flowTabDate',
    flowDeleteOneTitle: 'common.flowDeleteOneTitle',
    flowDeleteMultipleTitle: 'common.flowDeleteMultipleTitle',
    flowDeleteOneText: 'common.flowDeleteOneText',
    flowDeleteMultipleText: 'common.flowDeleteMultipleText',
    flowHasBeenDeleted: 'common.flowHasBeenDeleted',
    flowHasNotBeenDeleted: 'common.flowHasNotBeenDeleted',
    team: 'common.team',
    profil: 'common.profil',
    teamName: 'common.teamName',
    office: 'common.office',
    loggedIn: 'common.loggedIn',
    permissionError: 'common.permissionError',
    permissionAndAccess: 'common.permissionAndAccess',
    fieldNotFound: 'common.fieldNotFound',
    failToSave: 'common.failToSAve',
    security: 'common.security',
    metrics: 'common.metrics',
    copiedToClipBoard: 'common.copiedToClipBoard',
    setup: 'common.setup',
    setups: 'common.setups',
    addSetup: 'common.addSetup',
    noSetup: 'common.noSetup',
    noOptionAvailable: 'common.noOptionAvailable',
    noSetupGroup: 'common.noSetupGroup',
    editSetup: 'common.editSetup',
    createSetup: 'common.createSetup',
    download: 'common.download',
    file: 'common.file',
    files: 'common.files',
    regularCustomer: 'common.regularCustomer',
    punctualCustomer: 'common.punctualCustomer',
    creationOfNewFolder: 'common.creationOfNewFolder',
    emailFrom: 'common.emailFrom',
    workInProgress: 'common.workInProgress',
    document: 'common.document',
    flow: 'common.flow',
    information: 'common.information',
    exportConfiguration: 'common.exportConfiguration',
    userSettings: 'common.userSettings',
    confirmationRequired: 'common.confirmationRequired',
    rowsPerPage: 'common.rowsPerPage',
    details: 'common.details',
    backToHome: 'common.backToHome',
    message401: 'common.message401',
    message404: 'common.message404',
    message500: 'common.message500',
    titleErrorPage: 'common.titleErrorPage',
    messageErrorPage: 'common.messageErrorPage',
    key: 'common.key',
    configurations: 'commmon.configurations',
    userSettingsNoParameter: 'common.userSettingsNoParameter',
    commonError: 'common.commonError',
    author: 'common.author',
    noFiles: 'common.noFiles',
    notification: 'common.notification',
    createdBy: 'common.createdBy',
    sendBy: 'common.sendBy',
    updateSucceeded: 'common.updateSucceeded',
    updateFailed: 'common.updateFailed',
    filesDropInputWrongFormat: 'common.filesDropInputWrongFormat',
    hintMessageFirstText: 'common.hintMessageFirstText',
    hintMessageSecondText: 'common.hintMessageSecondText',
    hintMessageThirdText: 'common.hintMessageThirdText',
    hintMessageFourthText: 'common.hintMessageFourthText',
  },

  configuration: {
    configurationNameWarning: 'configurationNameWarning',
    typeTransportWarning: 'typeTransportWarning',
    nameTransportWarning: 'nameTransportWarning',
    addressTransportWarning: 'addressTransportWarning',
    portTransportWarning: 'portTransportWarning',
    emailAuthenticationTransportWarning: 'emailAuthenticationTransportWarning',
    passwordAuthenticationTransportWarning:
      'passwordAuthenticationTransportWarning',
    configurationSaved: 'configurationSaved',
    configurationName: 'configurationName',
    softwareName: 'softwareName',
    typeTransport: 'typeTransport',
    addressTransport: 'addressTransport',
    portTransport: 'portTransport',
    emailAuthenticationTransport: 'emailAuthenticationTransport',
    passwordAuthenticationTransport: 'passwordAuthenticationTransport',
  },

  upload: {
    exportService: 'upload.exportService',
    declarationType: 'upload.declarationType',
    workflow: 'upload.workflow',
    dropText: 'upload.dropText',
    emailHookText: 'upload.emailHookText',
    atLeastOneFileRequired: 'upload.atLeastOneFileRequired',
    warningOnNumberFilesMinimum: 'upload.warningOnNumberFilesMinimum',
    warningOnNumberFilesMaximum: 'upload.warningOnNumberFilesMaxiumum',
    requiredFiles: 'upload.requiredFiles',
    requiredCustomerType: 'upload.requiredCustomerType',
    requiredRecordName: 'upload.requiredRecordName',
    warningOnRecordNameLength: 'upload.warningOnRecordNameLength',
    requiredExportConfiguration: 'upload.requiredExportCOnfiguration',
    requiredDeclarationType: 'upload.requiredDeclarationType',
    requiredFluxConfiguration: 'upload.requiredFluxConfiguration',
    requiredGroup: 'upload.requiredGroup',
  },

  documents: {
    collaborators: 'documents.collaborators',
    statusExported: 'documents.statusExported',
    statusToRead: 'documents.statusToRead',
    statusExtraction: 'documents.statusExtraction',
    statusToTreat: 'documents.statusToTreat',
    statusError: 'documents.statusError',
    statusToSend: 'documents.statusToSend',
    statusSent: 'document.statusSent',
    visualizeDocuments: 'document.visualizeDocuments',
    downloadDeclarationXML: 'document.downloadDeclarationXML',
    downloadDetailValue: 'document.downloadDetailValue',
    downloadOrginalDocuments: 'document.downloadOrginalDocuments',
    typeDocument: 'document.typeDocument',
    nameDocument: 'document.nameDocument',
    statusInProgress: 'documents.statusInProgress',
    statusArchived: 'documents.statusArchived',
    statusUploaded: 'documents.statusUploaded',
    statusUnknown: 'documents.statusUnknown',
    removeRecordTitle: 'documents.removeRecordTitle',
    removeRecordsTitle: 'documents.removeRecordsTitle',
    removeRecordText: 'documemts.removeRecordText',
    removeRecordsText: 'documemts.removeRecordsText',
    removePendingRecordTitle: 'documents.removePendingRecordTitle',
    removePendingRecordsTitle: 'documents.removePendingRecordsTitle',
    removePendingRecordText: 'documemts.removePendingRecordText',
    removePendingRecordsText: 'documemts.removePendingRecordsText',
    documentHasBeenDeleted: 'documents.documentHasBeenDeleted',
    documentHasNotBeenDeleted: 'documents.documentHasNotBeenDeleted',
    documentHasBeenUpdated: 'documents.documentHasBeenUpdated',
    noDocumentFound: 'documents.noDocumentFound',
  },

  export: {
    confirmExportTitle: 'export.confirmExportTitle',
    confirmExportText: 'export.confirmExportText',
    confirmExport: 'export.confirmExport',
    successToExport: 'export.successToExport',
    notExtracted: 'export.notExtracted',
  },

  teams: {
    teamName: 'teams.teamName',
    removeFromGroup: 'teams.removeFromGroup',
    youHaveNoTeam: 'teams.youHaveNoTeam',
    createATeam: 'teams.createATeam',
    noRole: 'teams.noRole',
  },

  activity: {
    lastActivity: 'activity.lastActivity',
    subTitleLastActivity: 'activity.subTitleLastActivity',
    seeSentDocuments: 'activity.seeSentDocuments',
    seeDocumentsToManage: 'activity.seeDocumentsToManage',
    seeDocuments: 'activity.seeDocuments',
    exportedDocuments: 'activity.exportedDocuments',
  },

  nav: {
    account: 'nav.account',
    managementConsole: 'nav.managementConsole',
    analytics: 'nav.analytics',
    auth: 'nav.auth',
    browse: 'nav.browse',
    contact: 'nav.contact',
    create: 'nav.create',
    customers: 'nav.customers',
    dashboard: 'nav.dashboard',
    gobackToDeclaration: 'nav.gobackToDeclaration',
    backoffice: 'nav.backoffice',
    database: 'nav.database',
    customerFlow: 'nav.customerFlow',
    customer: 'nav.customer',
    flow: 'nav.flow',
    details: 'nav.details',
    edit: 'nav.edit',
    error: 'nav.error',
    feed: 'nav.feed',
    fileManager: 'nav.fileManager',
    files: 'nav.files',
    forgotPassword: 'nav.forgotPassword',
    list: 'nav.list',
    login: 'nav.login',
    layers: 'nav.layers',
    mail: 'nav.mail',
    management: 'nav.management',
    orderList: 'nav.orders',
    overview: 'nav.overview',
    organizations: 'nav.organizations',
    pages: 'nav.pages',
    postCreate: 'nav.postCreate',
    postDetails: 'nav.postDetails',
    postList: 'nav.postList',
    pricing: 'nav.pricing',
    productList: 'nav.products',
    fieldList: 'nav.fields',
    fieldToRead: 'nav.fieldsToRead',
    fieldToManage: 'nav.fieldsToManage',
    pendingField: 'nav.pendingField',
    fieldExported: 'nav.fieldsExported',
    fieldDeleted: 'nav.fieldDeleted',
    pendingDocuments: 'nav.pendingDocuments',
    sentDocuments: 'nav.sentDocuments',
    removedDocuments: 'nav.removedDocuments',
    profile: 'nav.profile',
    register: 'nav.register',
    resetPassword: 'nav.resetPassword',
    connectors: 'nav.connectors',
    users: 'nav.users',
  },

  backoffice: {
    createUser: 'backoffice.createUser',
    deleteUser: 'backoffice.deleteUser',
    successSendEmailMessage: 'backoffice.successSendEmailMessage',
    users: 'backoffice.users',
    importLayer: 'backoffice.importLayer',
    chooseLayerName: 'backoffice.chooseLayerName',
    selectDocumentType: 'backoffice.selectDocumentType',
    onlyFirstPage: 'backoffice.onlyFirstPage',
    acceptedFormats: 'backoffice.acceptedFormats',
    maxSize: 'backoffice.maxSize',
    exportConfig: 'backoffice.exportConfig',
    layerNameIsRequired: 'backoffice.layerNameIsRequired',
    documentTypeIsRequired: 'backoffice.documentTypeIsRequired',
    exportConfigurationIsRequired: 'backoffice.exportConfigurationIsRequired',
    deleteOrganization: 'backoffice.deleteOrganization',
  },

  organizations: {
    organizationsTitle: 'organizations.organizationsTitle',
    organizationTitle: 'organizations.organizationTitle',
  },

  dashboard: {
    batchImport: 'dashboard.batchImport',
    pendingRecords: 'dashboard.pendingRecords',
    recordsToManage: 'dashboard.recordToManage',
    exportedRecords: 'dashboard.exportedRecords',
    seeRecords: 'dashboard.seeRecords',
    seeExportedRecords: 'dashboard.seeExportedRecords',
    createOrganization: 'dashboard.createOrganization',
    createOffice: 'dashboard.createOffice',
    officeCreated: 'dashboard.officeCreated',
    officeDeleted: 'dashboard.officeDeleted',
    userCreated: 'dashboard.userCreated',
    userUpdated: 'dashboard.editCustomer',
    punctualCustomer: 'dashboard.punctualCustomer',
    regularCustomer: 'dashboard.regularCustomer',
    organizationCreated: 'dashboard.organizationCreated',
    deleteOrganization: 'dashboard.deleteOrganization',
    deleteUser: 'dashboard.deleteUser',
    organizationAndGroups: 'dashboard.organizationAndGroups',
    selectGroups: 'dashboard.selectGroups',
    inviteCollaborators: 'dashboard.inviteCollaborator',
    externalCollaborator: 'dashboard.externalCollaborator',
    removeCollaborator: 'dashboard.removeCollaborator',
    removeOrganizationTitle: 'dashboard.removeOrganizationTitle',
    removeOrganizationText: 'dashboard.removeOrganizationText',
    removeUserTitle: 'dashboard.removeUserTitle',
    removeUserText: 'dashboard.removeUserText',
    sendInvitationEmailTitle: 'dashboard.sendInvitationEmailTitle',
    sendInvitationEmailText: 'dashboard.sendInvitationEmailText',
    manageDocuments: 'dashboard.manageDocuments',
    dropFilesHere: 'dashboard.dropFilesHere',
    useNewFolderButton: 'dashboard.useNewFolderButton',
    newFolderButton: 'dashboard.newFolderButton',
    folderInformation: 'dashboard.folderInformation',
    folderName: 'dashboard.folderName',
    customer: 'dashboard.customer',
    exportService: 'dashboard.exportService',
    typeDeclarationService: 'dashboard.typeDeclarationService',
    cancel: 'dashboard.cancel',
    upload: 'dashboard.upload',
    addNewCustomer: 'dashboard.addNewCustomer',
    customerName: 'dashboard.customerName',
    createNewCustomer: 'dashboard.createNewCustomer',
    theEmailWasCopiedToYourClipboard:
      'dashboard.theEmailWasCopiedToYourClipboard',
    fastTrackFirstText: 'dashboard.fastTrackFirstText',
    fastTrackSecondText: 'dashboard.fastTrackSecondText',
    fastTrackTooltipText: 'dashboard.fastTrackTooltipText',
  },

  extraction: {
    index: 'extraction.index',
    chooseClassification: 'extraction.chooseClassification',
    exceptions: ',.exceptions',
    addData: 'extraction.addData',
    editGrid: 'extraction.editGrid',
    deleteTable: 'extraction.deleteTable',
    deleteTablePage: 'extraction.deleteTablePage',
    deleteAllTablePages: 'extraction.deleteAllTablePages',
    addColumn: 'extraction.addColumn',
    addRow: 'extraction.addRow',
    tableRows: 'extraction.tableRows',
    deleteRow: 'extraction.deleteRow',
    noExceptionsFound: 'extraction.noExceptionsFound',
    informations: 'extraction.informations',
    products: 'extraction.products',
    missingProduct: 'extraction.missingProduct',
    addInformation: 'extraction.addInformation',
    addElement: 'extraction.addElement',
    updatingData: 'extraction.updatingData',
    customInformation: 'extraction.customInformation',
    documentInformation: 'extraction.documentInformation',
    transactionDetails: 'extraction.transactionDetails',
    shippingInformation: 'extraction.shippingInformation',
    findField: 'extraction.findField',
    duplicate: 'extraction.duplicate',
    areYouSureYouWantToCopyDeclaration:
      'extraction.areYouSureYouWantToCopyDeclaration', // eslint-disable-line prettier/prettier
    thereWasAnErrorCopyingTheDeclaration:
      'extraction.thereWasAnErrorCopyingTheDeclaration', // eslint-disable-line
    groupByHSCode: 'extraction.groupByHSCode',
    cellTooSmall: 'extraction.cellTooSmall',
    cellNotValid: 'extraction.cellNotValid',
    doYouWantToGroupTokensByHSCode: 'extraction.doYouWantToGroupTokensByHSCode',
    yes: 'extraction.yes',
    no: 'extraction.no',
    invoices: 'extraction.invoices',
    'commercial-invoice': 'extraction.commercial-invoice',
    extraction: 'extraction.extraction',
    nothingToShow: 'extraction.nothingToShow',
    table: 'extraction.table',
    field: 'extraction.field',
    tables: 'extraction.tables',
    grids: 'extraction.grids',
    cells: 'extraction.cells',
    displayTheTable: 'extraction.displayTheTable',
    save: 'extraction.save',
    noOtherFormatsAvailable: 'extraction.noOtherFormatsAvailable',
    downloadTestFile: 'extraction.downloadTestFile',
    exportTo: 'extraction.exportTo',
    newField: 'extraction.newField',
    convertIntoField: 'extraction.convertIntoField',
    convertIntoTable: 'extraction.convertIntoTable',
    newTable: 'extraction.newTable',
    on: 'extraction.on',
    completedFields: 'extraction.completedFields',
    workComplete: 'extraction.workComplete',
    mustBeString: 'extraction.mustBeString',
    mustBeInteger: 'extraction.mustBeInteger',
    mustBeNumber: 'extraction.mustBeNumber',
    mustBeInAlpha2: 'extraction.mustBeInAlpha2',
    doitTreAuFormatAlpha2ExemplePourFranceFr:
      'extraction.doitTreAuFormatAlpha2ExemplePourFranceFr',
    mustMatchFormatDateTime: 'extraction.mustMatchFormatDateTime',
    mustMatchFormatDate: 'extraction.mustMatchFormatDate',
    pleaseEnterExactly10CharactersInFormat:
      'extraction.pleaseEnterExactly10CharactersInFormat',
    mustNotHaveMoreThanCharacters: 'extraction.mustNotHaveMoreThanCharacters',
    mustNotHaveFewerThanCharacters: 'extraction.mustNotHaveFewerThanCharacters',
    pleaseEnterBetweenXAndYCharacters:
      'extraction.pleaseEnterBetweenXAndYCharacters',
    pleaseEnterExactlyXCharacters: 'extraction.pleaseEnterExactlyXCharacters',
    pleaseEnterAWholeNumber: 'extraction.pleaseEnterAWholeNumber',
    '18LettersAndNumbersMaximum': 'extraction.18LettersAndNumbersMaximum',
    pleaseEnterANumber: 'extraction.pleaseEnterANumber',
    pleaseEnterAMaximumOf: 'extraction.pleaseEnterAMaximumOf',
    pleaseSelectFromTheGivenValues: 'extraction.pleaseSelectFromTheGivenValues',
    pleaseEnterAValueThatConsistsOfLetters:
      'extraction.pleaseEnterAValueThatConsistsOfLetters',
    pleaseEnterAValidSiretNumber: 'extraction.pleaseEnterAValidSiretNumber',
    loadingExtractionInformation: 'extraction.loadingExtractionInformation',
    loadingBackToDashboard: 'extraction.loadingBackToDashboard',
    deleteColumn: 'extraction.deleteColumn',
    doYouWantToDeleteTable: 'extraction.doYouWantToDeleteTable',
    doYouWantToDeleteTableOnCurrentPage:
      'extraction.doYouWantToDeleteTableOnCurrentPage',
    editTable: 'extraction.editTable',
    editCells: 'extraction.editCells',
    editField: 'extraction.editField',
    deleteTableOnlyInThePage: 'extraction.deleteTableOnlyInThePage',
    deleteTableInAllPage: 'extraction.deleteTableInAllPage',
    doYouWantToDeleteColumn: 'extraction.doYouWantToDeleteColumn',
    doYouWantToDeleteColumnAndDeconsolidate:
      'extraction.doYouWantToDeleteColumnAndDeconsolidate',
    doYouWantToEditColumnAndDeconsolidate:
      'extraction.doYouWantToEditColumnAndDeconsolidate',
    addOriginOfArticles: 'extraction.addOriginOfArticles',
    addHSCodeOfArticles: 'extraction.addHSCodeOfArticles',
    addCodePrefOfArticles: 'extraction.addCodePrefOfArticles',
    addOriginCountryCode: 'extraction.addOriginCountryCode',
    codePreferenceModalTitle: 'extraction.codePreferenceModalTitle',
    codePreferenceCopy: 'extraction.codePreferenceCopy',
    ofItems: 'extraction.ofItems',
    add: 'extraction.add',
    doYouWantToDeleteRow: 'extraction.doYouWantToDeleteRow',
    createTable: 'extraction.createTable',
    emptyTable: 'extraction.emptyTable',
    addToTable: 'extraction.addToTable',
    declarationState: 'extraction.declarationState',
    returnToDeclarations: 'extraction.returnToDeclarations',
    updateDeclaration: 'extraction.updateDeclaration',
    declarationUploading: 'extraction.declarationUploading',
    declarationUploaded: 'extraction.declarationUploaded',
    youWillBeRedirectedToWorkflowCreation:
      'extraction.youWillBeRedirectedToWorkflowCreation',
    youWillBeRedirectedToTheDashboard:
      'extraction.youWillBeRedirectedToTheDashboard',
    declarationUploadFailed: 'extraction.declarationUploadFailed',
    checkDeclarationAndRetry: 'extraction.checkDeclarationAndRetry',
    checkDeclaration: 'extraction.checkDeclaration',
    warningOriginX: 'extraction.warningOriginX',
    warningOriginY: 'extraction.warningOriginY',
    warningTouchingCellsOnY: 'extraction.warningTouchingCellsOnY',
    warningTouchingCellsOnX: 'extraction.warningTouchingCellsOnX',
    warningBottomRowTooSmall: 'extraction.warningBottomRowTooSmall',
    warningTopRowTooSmall: 'extraction.warningTopRowTooSmall',
    warningTableLeavesPage: 'extraction.warningTableLeavesPage',
    consolidate: 'extraction.consolidate',
    consolidated: 'extraction.consolidated',
    sendingConfirmation: 'extraction.sendingConfirmation',
    createWorkflowInfo: 'extraction.createWorkflowInfo',
    sendingFileQuestion: 'extraction.sendingFileQuestion',
    saveWorkflowQuestion: 'extraction.saveWorkflowQuestion',
    createNewWorkflow: 'extraction.createNewWorkflow',
    redirectCreateWorkflow: 'extraction.redirectCreateWorkflow',
    fluxFieldsAreFixed: 'extraction.fluxFieldsAreFixed',
    selectAllFields: 'extraction.selectAllFields',
    workflowDetail: 'extraction.workflowDetail',
    workflowCreation: 'extraction.workflowCreation',
    workflowCreated: 'extraction.workflowCreated',
    workflowCreationFailed: 'extraction.workflowCreationFailed',
    flowApplyChip: 'extraction.flowApplyChip',
    glideHeaderSum: 'extraction.glideHeaderSum',
    glideHeaderArticleNumber: 'extraction.glideHeaderArticleNumber',
    checkFieldModalFirstTextWarning:
      'extraction.checkFieldModalFirstTextWarning', // eslint-disable-line
    checkFieldModalFirstText: 'extraction.checkFieldModalFirstText',
    checkFieldModalFirstTextEnding: 'extraction.checkFieldModalFirstTextEnding',
    checkFieldModalSecondText: 'extraction.checkFieldModalSecondText',
    checkFieldModalFieldUpdated: 'extraction.checkFieldModalFieldUpdated',
    checkFieldModalConsolidatedTitle:
      'extraction.checkFieldModalConsolidatedTitle',
    checkFieldModalConsolidatedFirstText:
      'extraction.checkFieldModalConsolidatedFirstText',
    checkFieldModalConsolidatedSecondText:
      'extraction.checkFieldModalConsolidatedSecondText',
    checkFieldModalConsolidatedUpdatedButton:
      'extraction.checkFieldModalConsolidatedUpdatedButton',
    checkFieldModalConsolidatedSendButton:
      'extraction.checkFieldModalConsolidatedSendButton',
    checkFieldModalDependenciesWarning:
      'extraction.checkFieldModalDependenciesWarning',
    missingValuesInColumn: 'extraction.missingValuesInColumn',
    columnMissing: 'extraction.columnMissing',
    resetLabelBox: 'extraction.resetLabelBox',
  },
  layers: {
    importLayerSource: 'layers.importLayerSource',
    layerJson: 'layers.layerJson',
    metadataJson: 'layers.metadataJson',
    import: 'layers.import',
    yourLayers: 'layers.yourLayers',
    searchForALayer: 'layers.searchForALayer',
    doYouWantToDeleteTheLayer: 'layers.doYouWantToDeleteTheLayer',
    prefillClassification: 'layers.prefillClassification',
    prefillValue: 'layers.prefillValue',
    prefill: 'layers.prefill',
    createANewLayer: 'layers.createANewLayer',
    chooseLayerName: 'layers.chooseLayerName',
    selectFlow: 'layer.selectFlow',
    selectDocumentType: 'layers.selectDocumentType',
    uploadRestrictions: 'layers.uploadRestrictions',
    maxSize10MB: 'layers.maxSize10MB',
    selectFile: 'layers.selectFile',
    selectedFile: 'layers.selectedFile',
    addLayer: 'layer.add',
    createLayer: 'layer.create',
    importLayer: 'layer.import',
    exportLayer: 'layer.export',
    deleteLayer: 'layer.delete',
    deleteLayerShort: 'layer.delete.short',
    editLayer: 'layer.edit',
    editLayerShort: 'layer.edit.short',
    extraction: 'layer.extraction',
    postprocess: 'layer.postprocess',
    data: 'layer.data',
    addRule: 'layer.addRule',
    rules: 'layer.rules',
    noRules: 'layer.noRules',
    fieldList: 'layer.fieldList',
    tables: 'layer.table',
    save: 'layer.save',
    prefillData: 'layer.prefillData',
    addPrefillData: 'layer.addPrefillData',
    filter: 'layer.filter',
    extract: 'layer.extract',
    postprocessApplyOn: 'layer.postprocessApplyOn',
    postprocessExtractFrom: 'layer.postprocessExtractFrom',
    postprocessAddTo: 'layer.postprocessAddTo',
    column: 'layer.column',
    newColumn: 'layer.newColumn',
    flowName: 'layer.flowName',
    layerName: 'layer.layerName',
    active: 'layer.active',
    lastUpdate: 'layer.lastUpdate',
    actions: 'layer.actions',
    sender: 'layer.sender',
    receiver: 'layer.receiver',
    documentType: 'layer.documentType',
  },
  flows: {
    sender: 'flows.sender',
    receiver: 'flows.receiver',
    flowType: 'flows.flowType',
    exportContext: 'flows.exportContext',
  },
  settings: {
    noDocumentsFound: 'settings.noDocumentsFound',
  },
  segmentation: {
    finishSegmentationStep: 'segmentation.finishSegmentationStep',
    merge: 'segmentation.merge',
    cut: 'segmentation.cut',
    deletePage: 'segmentation.deletePage',
    keep: 'segmentation.keep',
  },
  floatinglabelform: {
    save: 'floatinglabelform.save',
    apply: 'floatinglabelform.apply',
    text: 'floatinglabelform.text',
  },
  viewer: {
    doYouWantToDeleteTheTable: 'viewer.doYouWantToDeleteTheTable',
    apply: 'viewer.apply',
    adjustTheDocument: 'viewer.adjustTheDocument',
    selectedLabel: 'viewer.selectedLabel',
    selectADocument: 'viewer.selectADocument',
    applyALayer: 'viewer.applyALayer',
    newColumn: 'viewer.newColumn',
    invoice: 'viewer.invoice',
  },
  classification: {
    manualLayer: 'classification.manualLayer',
    searchClassificationType: 'classification.searchClassificationType',
    outOf: 'classification.outOf',
    validate: 'classification.validate',
    unknownDocument: 'classification.unknownDocument',
    unknownDocuments: 'classification.unknownDocuments',
    addExtractionMode: 'classification.addExtractionMode',
    ignoreDocument: 'classification.ignoreDocument',
    includeDocument: 'classification.includeDocument',
    fieldRequired: 'classification.fieldRequired',
    selectExtractionMode: 'classification.selectExtractionMode',
    onlyInvoicesAllowed: 'classification.onlyInvoicesAllowed',
    atLeastOneInvoiceorOneEAD: 'classification.atLeastOneInvoiceorOneEAD',
    someDocumentsWithoutClassification:
      'classification.someDocumentsWithoutClassification',
    hasNoExportConfiguration: 'classification.hasNoExportConfiguration',
    document: 'classification.document',
    document_plural: 'classification.document_plural',
    page: 'classification.page',
    page_plural: 'classification.page_plural',
    MT700: 'classification.MT700',
    weight_note: 'classification.weight_note',
    exchange_bill: 'classification.exchange_bill',
    origin_certif: 'classification.origin_certif',
    multimodal_doc: 'classification.multimodal_doc',
    flight_letter: 'classification.flight_letter',
    sea_waybill: 'classification.sea_waybill',
    road_document: 'classification.road_document',
    delivery_note: 'classification.delivery_note',
    insurance_policy: 'classification.insurance_policy',
    bill_of_lading: 'classification.bill_of_lading',
    single_administrative_document:
      'classification.single_administrative_document',
    commercial_invoice: 'classification.commercial_invoice',
    customs_instructions: 'classification.customs_instructions',
    packing_list: 'classification.packing_list',
    EAD: 'classification.electronic_accompanying_document',
    electronic_accompanying_document:
      'classification.electronic_accompanying_document',
    conversation: 'classification.conversation',
    MT700_short: 'classification.MT700_short',
    weight_note_short: 'classification.weight_note_short',
    exchange_bill_short: 'classification.exchange_bill_short',
    origin_certif_short: 'classification.origin_certif_short',
    multimodal_doc_short: 'classification.multimodal_doc_short',
    flight_letter_short: 'classification.flight_letter_short',
    sea_waybill_short: 'classification.sea_waybill_short',
    road_document_short: 'classification.road_document_short',
    delivery_note_short: 'classification.delivery_note_short',
    insurance_policy_short: 'classification.insurance_policy_short',
    bill_of_lading_short: 'classification.bill_of_lading_short',
    single_administrative_document_short:
      'classification.single_administrative_document_short',
    commercial_invoice_short: 'classification.commercial_invoice_short',
    customs_instructions_short: 'classification.customs_instructions_short',
    packing_list_short: 'classification.packing_list_short',
    EAD_short: 'classification.electronic_accompanying_document_short',
    electronic_accompanying_document_short:
      'classification.electronic_accompanying_document_short',
    conversation_short: 'classifcation.conversation_short',
  },
  connector: {
    connectorName: 'connector.name',
    addConnector: 'connector.add',
  },
  filter: {
    sortBy: 'sortBy',
    searchOrganization: 'searchOrganization',
    searchUser: 'searchUser',
    searchLayer: 'searchLayer',
    lastUpdateNewest: 'lastUpdateNewest',
    lastUpdateOldest: 'lastUpdateOldest',
  },
  customerFlow: {
    customer: 'customer',
    flow: 'flow',
    documentType: 'documentType',
    removeCustomerTitle: 'removeCustomerTitle',
    removeCustomerText: 'removeCustomerText',
    customerHasBeenDeleted: 'customerHasBeenDeleted',
    customerHasNotBeenDeleted: 'customerHasNotBeenDeleted',
    removeFlowTitle: 'removeFlowTitle',
    removeFlowText: 'removeFlowText',
    flowHasBeenDeleted: 'flowHasBeenDeleted',
    flowHasNotBeenDeleted: 'flowHasNotBeenDeleted',
    settingKeyDeleted: 'settingKeyDeleted',
    actionFailed: 'actionFailed',
    settingKeyCreated: 'settingKeyCreated',
    settingKeyUpdated: 'settingKeyUpdated',
    sender: 'sender',
    flowType: 'flowType',
    informations: 'informations',
    documentModels: 'documentModels',
    nameOfModel: 'nameOfModel',
    issuesSettingsOptions: 'issuesSettingsOptions',
    noFluxAvailable: 'noFluxAvailable',
    unknownUser: 'unknownUser',
  },
  customer: {
    addNew: 'customer.addNew',
    basicInformation: 'customer.basicInformation',
    identifiers: 'customer.identifiers',
    address: 'customer.address',
    addressRequired: 'customer.addressRequired',
    corporateName: 'customer.corporateName',
    corporateNameRequired: 'customer.corporateNameRequired',
    societyType: 'customer.societyType',
    societyTypeRequired: 'customer.societyTypeRequired',
    siretNumber: 'customer.siretNumber',
    siretNumberRequired: 'customer.siretNumberRequired',
    eoriNumber: 'customer.eoriNumber',
    eoriNumberRequired: 'customer.eoriNumberRequired',
    vatNumber: 'customer.vatNumber',
    vatNumberRequired: 'customer.vatNumberRequired',
    rexNumber: 'customer.rexNumber',
    rexNumberRequired: 'customer.rexNumberRequired',
    postalCode: 'customer.postalCode',
    postalCodeRequired: 'customer.postalCodeRequired',
    city: 'customer.city',
    cityRequired: 'customer.cityRequired',
    country: 'customer.country',
    countryRequired: 'customer.countryRequired',
    otherSettings: 'customer.otherSettings',
    settingValueRequired: 'customer.settingValueRequired',
  },

  managementConsole: {
    title: 'managementConsole.title',
    //tabs
    establishmentsAndGroups: 'managementConsole.establishmentsAndGroups',
    metrics: 'managementConsole.metrics',
    member_one: 'managementConsole.member_one',
    member_other: 'managementConsole.member_other',
    members: 'managementConsole.members',
    general: 'managementConsole.general',
    organizationSettings: 'managementConsole.organizationSettings',
    //general sectinon
    companyInformation: 'managementConsole.companyInformation',
    companyNameRequired: 'managementConsole.companyNameRequired',
    registrationNumberRequired: 'managementConsole.registrationNumberRequired',
    registrationNumberWarning: 'managementConsole.registrationNumberWarning',
    registrationCountryRequired:
      'managementConsole.registrationCountryRequired',
    registrationCountryWarning: 'managementConsole.registrationCountryWarning',
    organizationUpdated: 'managementConsole.organizationUpdated',
    warningToSaveTitle: 'managementConsole.warningToSave',
    warningToSaveConfirmButtonText:
      'managementConsole.warningToSaveConfirmButtonText',
    warningToSaveCancelButtonText:
      'managementConsole.warningToSaveCancelButtonText',
    //OrganizationSettings
    organizationSettingsUpdated:
      'managementConsole.organizationSettingsUpdated',
    issuesSettingsOptions: 'managementConsole.issuesSettingsOptions',
    generalSettings: 'managementConsole.generalSettings',
    customsPreFilling: 'managementConsole.customsPreFilling',
    lockedFieldTooltip: 'managementConsole.lockedFieldTooltip',
    unlockedFieldTooltip: 'managementConsole.unlockedFieldTooltip',
    //Establisment&Groups
    establishmentAndGroup: 'managementConsole.establishmentAndGroup',
    administrator: 'managementConsole.administrator',
    //Establishment
    establishment: 'managementConsole.establishment',
    errorUpdatingSettings: 'managementConsole.errorUpdatingSettings',
    resetSettings: 'managementConsole.resetSettings',
    settingsHasBeenUpdated: 'managementConsole.settingsHasBeenUpdated',
    impossibleToLockEmptField: 'managementConsole.impossibleToLockEmptField',
    settingOfGroup: 'managementConsole.settingOfGroup',
  },

  account: {
    generalSettings: 'account.generalSettings',
    customsPreFilling: 'account.customsPreFilling',
    settingsHasBeenUpdated: 'account.settingsHasBeenUpdated',
    errorUpdatingSettings: 'account.errorUpdatingSettings',
    resetSettings: 'account.resetSettings',
  },
  store: {
    anErrorOccurred: 'store.anErrorOccurred',
    unableToUpdateBoundingRectangle: 'store.unableToUpdateBoundingRectangle',
    targetExportFieldIsNotEmpty: 'store.targetExportFieldIsNotEmpty',
    unableToCreateColumnSomeCellsAreInvalid:
      'store.unableToCreateColumnSomeCellsAreInvalid',
    unableToCreateColumnTableAndColumnRowsMustBeIdentical:
      'store.unableToCreateColumnTableAndColumnRowsMustBeIdentical',
    thereIsAlreadyATableOnThisPage: 'store.thereIsAlreadyATableOnThisPage',
    unableToProjectCellsOntoNewTable: 'store.unableToProjectCellsOntoNewTable',
    unableToProjectEmptyRowOntoNewPage:
      'store.unableToProjectEmptyRowOntoNewPage',
    newTableIsTooSmallToProjectCellsFromExistingTable:
      'store.newTableIsTooSmallToProjectCellsFromExistingTable',
    tableHasNoRows: 'store.tableHasNoRows',
    unableToIdentifyTheCurrentRow: 'store.unableToIdentifyTheCurrentRow',
    cannotDragTableCellIntoADifferentRow:
      'store.cannotDragTableCellIntoADifferentRow',
    tableCellTooSmallOrInverted: 'store.tableCellTooSmallOrInverted',
    tableCellCannotGoOutOfItsRowVertically:
      'store.tableCellCannotGoOutOfItsRowVertically',
    tableCellGoesOutOfTableOnTheLeft: 'store.tableCellGoesOutOfTableOnTheLeft',
    tableCellGoesOutOfTableOnTheRight:
      'store.tableCellGoesOutOfTableOnTheRight',
    tableCellGoesOutOfTableOnTheTop: 'store.tableCellGoesOutOfTableOnTheTop',
    tableCellGoesOutOfTableOnTheBottom:
      'store.tableCellGoesOutOfTableOnTheBottom',
  },
}
