import { lazy, Suspense, useState } from 'react'
import type { RouteObject } from 'react-router'
import { Outlet } from 'react-router-dom'
import { IssuerGuard } from '@/guards/issuer-guard'
import { AuthGuard } from '@/guards/auth-guard'
import { Issuer } from '@/types/auth'
import { Layout as DashboardLayout } from '@/layouts/dashboard'
import { wrapWithErrorBoundary } from '@/routes/wrapWithErrorBoundary'
import { defaultConfig } from '@/config'

const ActivityPage = lazy(() => import('@/pages/dashboard/activity'))

// Documents
const DocumentListPage = lazy(() => import('@/pages/dashboard/documents/list'))

const AccountPage = lazy(() => import('@/pages/dashboard/account'))

const ExtractionPage = lazy(() => import('@/pages/extraction'))
const ClassificationPage = lazy(() => import('@/pages/classification'))
const CustomerFlowPage = lazy(() => import('@/pages/customerFlow'))
const AccountOrganizationPage = lazy(
  () => import('@/pages/accountOrganization')
)

const WrappedActivityPage = wrapWithErrorBoundary(ActivityPage)
const WrappedDocumentListPage = wrapWithErrorBoundary(DocumentListPage)
const WrappedAccountPage = wrapWithErrorBoundary(AccountPage)
const WrappedExtractionPage = wrapWithErrorBoundary(ExtractionPage)
const WrappedClassificationPage = wrapWithErrorBoundary(ClassificationPage)
const WrappedCustomerFlowPage = wrapWithErrorBoundary(CustomerFlowPage)
const WrappedAccountOrganizationPage = wrapWithErrorBoundary(
  AccountOrganizationPage
)

const DashboardLayoutWrapper = (props: any) => {
  const [isFullscreen, setIsFullscreen] = useState(false)

  return (
    <IssuerGuard issuer={Issuer.JWT}>
      <DashboardLayout isFullscreen={isFullscreen}>
        <AuthGuard role={null}>
          <Suspense>
            <Outlet context={{ setIsFullscreen }} />
          </Suspense>
        </AuthGuard>
      </DashboardLayout>
    </IssuerGuard>
  )
}

export const dashboardRoutes: RouteObject[] = [
  {
    path: '/',
    element: <DashboardLayoutWrapper />,
    children: [
      {
        index: true,
        element: <WrappedActivityPage />,
      },
      {
        path: 'documents',
        element: (
          <WrappedDocumentListPage
            selectedStatus={[
              'mapping',
              'mapped',
              'mapping',
              'segmented',
              'extracting',
              'upload',
              'failed',
            ]}
            title={'pendingDocuments'}
            displayPendingRecords={true}
            displayFileDropZone={true}
          />
        ),
      },
      {
        path: 'documents/manage',
        element: (
          <WrappedDocumentListPage
            selectedStatus={[
              'mapping',
              'mapped',
              'mapping',
              'segmented',
              'extracting',
              'upload',
              'failed',
            ]}
            title={'pendingDocuments'}
            displayPendingRecords={true}
            displayFileDropZone={true}
          />
        ),
      },
      {
        path: 'documents/exported',
        element: (
          <WrappedDocumentListPage
            selectedStatus={['exported']}
            title={'sentDocuments'}
            displayPendingRecords={false}
            displayFileDropZone={false}
          />
        ),
      },
      {
        path: 'documents/deleted',
        element: (
          <WrappedDocumentListPage
            selectedStatus={['deleted']}
            title={'removedDocuments'}
            displayPendingRecords={false}
            displayFileDropZone={false}
          />
        ),
      },
      {
        path: 'documents/:id/extraction',
        element: <WrappedExtractionPage />,
      },
      {
        path: 'documents/:id/classification',
        element: <WrappedClassificationPage />,
      },
      {
        path: 'account',
        element: <WrappedAccountPage />,
      },
      {
        path: 'account/organization/:id',
        element: <WrappedAccountOrganizationPage />,
      },
      {
        path: 'customer-flow',
        element: <WrappedCustomerFlowPage />,
      },
      {
        path: 'customer-flow/flow',
        element: <WrappedCustomerFlowPage display="flow" />,
      },
      {
        path: 'customer-flow/flow/:id',
        element: <WrappedCustomerFlowPage display="flow-detail" />,
      },
      ...(defaultConfig().envMode === 'development'
        ? [
            {
              path: 'customer-flow/customer',
              element: <WrappedCustomerFlowPage display="customer" />,
            },
          ]
        : []),
    ],
  },
]
