import type { FC } from 'react'
import { useTheme } from '@mui/material/styles'

interface LogoProps {
  width?: number
  height?: number
}
export const Logo: FC<LogoProps> = ({ width = 48, height = 48 }) => {
  const theme = useTheme()
  const fillColor = theme.palette.primary.main

  return (
    <>
      <img
        src="/assets/nabu-logo.png"
        className="logo nabu"
        alt="Nabu logo"
        style={{ width, height }}
      />
    </>
  )
}
