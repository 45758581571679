import { lazy } from 'react'
import type { RouteObject } from 'react-router'
import { Outlet } from 'react-router-dom'
import { IssuerGuard } from '@/guards/issuer-guard'
import { GuestGuard } from '@/guards/guest-guard'
import { Issuer } from '@/types/auth'
import { Layout as AuthModernLayout } from '@/layouts/auth/modern-layout'
import { wrapWithErrorBoundary } from '@/routes/wrapWithErrorBoundary'

// JWT
const JwtLoginPage = lazy(() => import('@/pages/auth/jwt/login'))
const JwtForgotPasswordPage = lazy(
  () => import('@/pages/auth/jwt/forgot-password')
)
const JwtRenewPasswordPage = lazy(
  () => import('@/pages/auth/jwt/renew-my-password')
)
const JwtNewNaberPage = lazy(() => import('@/pages/auth/jwt/new-naber'))

const WrappedJwtLoginPage = wrapWithErrorBoundary(JwtLoginPage)
const WrappedJwtForgotPasswordPage = wrapWithErrorBoundary(JwtForgotPasswordPage)
const WrappedJwtRenewPasswordPage = wrapWithErrorBoundary(JwtRenewPasswordPage)
const WrappedJwtNewNaberPage = wrapWithErrorBoundary(JwtNewNaberPage)



export const authRoutes: RouteObject[] = [
  {
    path: 'auth',
    children: [
      {
        path: 'jwt',
        element: (
          <IssuerGuard issuer={Issuer.JWT}>
            <GuestGuard>
              <AuthModernLayout>
                <Outlet />
              </AuthModernLayout>
            </GuestGuard>
          </IssuerGuard>
        ),
        children: [
          {
            path: 'login',
            element: <WrappedJwtLoginPage />,
          },
          {
            path: 'forgot-password',
            element: <WrappedJwtForgotPasswordPage />,
          },
          {
            path: 'renew-my-password',
            element: <WrappedJwtRenewPasswordPage />,
          },

          {
            path: 'new-naber',
            element: <WrappedJwtNewNaberPage />,
          },
        ],
      },
    ],
  },
]
