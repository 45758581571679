import { FC } from 'react'

interface ImageWithChevronProps {
  /**
   * The url of the image to display
   */
  url: string
  flagLocal?: boolean
}

const ImageWithChevron: FC<ImageWithChevronProps> = ({ url, flagLocal }) => {
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <img
        src={flagLocal ? url : `https://${url}`}
        alt="Thumbnail"
        style={{
          height: flagLocal ? 'auto' : '500px',
          objectFit: 'contain',
          maxWidth: '100%',
        }}
      />
    </div>
  )
}

export default ImageWithChevron
