import { lazy, Suspense, useState } from 'react'
import type { RouteObject } from 'react-router'
import { Outlet } from 'react-router-dom'
import { IssuerGuard } from '@/guards/issuer-guard'
import { AuthGuard } from '@/guards/auth-guard'
import { Issuer } from '@/types/auth'
import { Layout as DashboardLayout } from '@/layouts/dashboard'
import { Role } from '@/api'
import { wrapWithErrorBoundary } from './wrapWithErrorBoundary'

const ManagementConsolePage = lazy(() => import('@/pages/managementConsole'))
const MCEstablishment = lazy(
  () => import('@/pages/managementConsole/establisment')
)

const WrapperManagementConsolePage = wrapWithErrorBoundary(
  ManagementConsolePage
)
const WrappedEstablishmentPage = wrapWithErrorBoundary(MCEstablishment)

const ManagementConsoleLayoutWrapper = (props: any) => {
  const [isFullscreen, setIsFullscreen] = useState(false)

  return (
    <IssuerGuard issuer={Issuer.JWT}>
      <DashboardLayout isFullscreen={isFullscreen}>
        <AuthGuard role={[Role.Superadmin, Role.Admin, Role.Manager]}>
          <Suspense>
            <Outlet context={{ setIsFullscreen }} />
          </Suspense>
        </AuthGuard>
      </DashboardLayout>
    </IssuerGuard>
  )
}

export const managementConsoleRoutes: RouteObject[] = [
  {
    path: '/',
    element: <ManagementConsoleLayoutWrapper />,
    children: [
      {
        path: '/management-console',
        element: <WrapperManagementConsolePage />,
      },
      {
        path: '/management-console/establishment/:id',
        element: <WrappedEstablishmentPage />,
      },
    ],
  },
]
