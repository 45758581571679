import useTranslate from '@/hooks/use-translate'
import * as Yup from 'yup'

export const clientSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  coid: Yup.string().required('COID is required'),
})

export default () => {
  const { t: tUpload } = useTranslate('upload')

  return Yup.object().shape({
    files: Yup.array()
      .min(1, tUpload('warningOnNumberFilesMinimum'))
      .required(tUpload('requiredFiles')),
    recordName: Yup.string()
      .required(tUpload('requiredRecordName'))
      .max(32, tUpload('warningOnRecordNameLength')),
    exportConfiguration: Yup.string().test(
      'required-declaration-type',
      tUpload('requiredDeclarationType'),
      function (value) {
        const workflow = this.parent.workflow // Récupère la valeur de workflow
        if (workflow !== undefined && workflow !== null) {
          // Si workflow est défini, exportConfiguration est requis
          return !!value
        }
        // Sinon, exportConfiguration n'est pas requis
        return true
      }
    ),
    workflow: Yup.string(),
    group: Yup.string().required(tUpload('requiredGroup')),
  })
}
