import { useEffect, type FC, type ReactNode } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Logo } from '@/components/Logo'
import useTranslate from '@/hooks/use-translate'
import { nabuColors } from '@/theme/colors'
import SelectMenuLanguage from './SelectMenuLanguage'
import { useTranslation } from 'react-i18next'

interface LayoutProps {
  children: ReactNode
}

/**
 * This is the Login page where we can see the Welcome texts and Loging From
 * @param props
 * @returns
 */
export const Layout: FC<LayoutProps> = (props) => {
  const { children } = props
  const { t: tCommon } = useTranslate('common')
  const { i18n } = useTranslation()

  const localStorageLanguage: string | null | undefined =
    localStorage.getItem('language')

  // This useEffect is here to keep the selected language when we refresh the navigator
  // Without it, the language is reset to Fr language
  useEffect(() => {
    if (localStorageLanguage && i18n.language !== localStorageLanguage) {
      i18n.changeLanguage(localStorageLanguage)
    }
  }, [i18n, localStorageLanguage])

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: {
          xs: 'column-reverse',
          md: 'row',
        },
        position: 'relative',
      }}
    >
      <div style={{ position: 'absolute', right: '15px', top: '15px' }}>
        <SelectMenuLanguage />
      </div>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'neutral.800',
          backgroundImage: 'url("/assets/gradient-bg.svg")',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          color: 'common.white',
          display: 'flex',
          flex: {
            xs: '0 0 auto',
            md: '1 1 auto',
          },
          justifyContent: 'center',
          p: {
            xs: 4,
            md: 8,
          },
        }}
      >
        <Box maxWidth="md">
          <Typography sx={{ mb: 4 }} variant="h2">
            <Box
              sx={{
                display: 'inline-flex',
                height: 48,
                width: 48,
                mr: 2,
              }}
            >
              <Logo />
            </Box>
            {tCommon('welcomeLogin')}
          </Typography>
          <Typography color={nabuColors.greySteel} sx={{ mb: 4 }}>
            {tCommon('welcomeLoginText')}
          </Typography>
          <Stack
            alignItems="center"
            direction="row"
            flexWrap="wrap"
            gap={4}
            sx={{
              color: 'text.primary',
              '& > *': {
                color: 'neutral.400',
                flex: '0 0 auto',
              },
            }}
          ></Stack>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          display: 'flex',
          flex: {
            xs: '1 1 auto',
            md: '0 0 auto',
          },
          flexDirection: 'column',
          justifyContent: {
            md: 'center',
          },
          maxWidth: '100%',
          p: {
            xs: 4,
            md: 8,
          },
          width: {
            md: 600,
          },
        }}
      >
        <div>
          <Box sx={{ mb: 4 }}></Box>
          {children}
        </div>
      </Box>
    </Box>
  )
}
