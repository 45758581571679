export interface ApiConfig {
  apiUrl: string
  apiPort: string
}

export function apiConfig(): ApiConfig {
  return {
    apiUrl: import.meta.env.VITE_API_URL || 'VITE_API_URL_undefined',
    apiPort: import.meta.env.VITE_API_PORT || 'VITE_API_PORT_undefined',
  }
}

export interface DefaultConfig {
  envMode: string
  defaultLayer: string
  defaultLayerEAD: string
  defaultExportUiForm: string
  cdnNabu: string
  sentryDsn: string
  zendeskApiKey: string
  helpNabu: string
}

export function defaultConfig(): DefaultConfig {
  return {
    envMode: import.meta.env.VITE_NODE_ENV || 'development',
    defaultLayer:
      import.meta.env.VITE_DEFAULT_LAYER || 'VITE_DEFAULT_LAYER_undefined',
    defaultLayerEAD:
      import.meta.env.VITE_DEFAULT_LAYER_EAD || 'VITE_DEFAULT_LAYER_undefined',
    defaultExportUiForm:
      import.meta.env.VITE_DEFAULT_EXPORT_UI_FORM ||
      'VITE_DEFAULT_EXPORT_UI_FORM_undefined',
    cdnNabu: import.meta.env.VITE_CDN_NABU,
    sentryDsn: import.meta.env.VITE_SENTRY_DSN || undefined,
    zendeskApiKey: import.meta.env.VITE_ZENDESK_API_KEY || undefined,
    helpNabu: import.meta.env.VITE_HELP_NABU || undefined,
  }
}

/*sur nabu-dev
console.log(import.meta.env.MODE) //production
console.log(import.meta.env.DEV) //false
console.log(import.meta.env.NODE_ENV ) //aucun = undefined
console.log(import.meta.env.VITE_DEFAULT_LAYER) // uuid
console.log( process.env.NODE_ENV ) //production
console.log(import.meta.env.VITE_NODE_ENV) //development
console.log(process.env.VITE_NODE_ENV)//development
*/

/*sur nabu-staging
console.log(import.meta.env.MODE) //production
console.log(defaultConfig().envDev ?? '') //false
console.log(import.meta.env.NODE_ENV ) //aucun = undefined
console.log(import.meta.env.VITE_DEFAULT_LAYER) // uuid
console.log( process.env.NODE_ENV ) //production
console.log(import.meta.env.VITE_NODE_ENV) //staging
console.log(process.env.VITE_NODE_ENV)//staging
*/
