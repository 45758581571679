import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { en } from './translations/en'
import { fr } from './translations/fr'

navigator.language

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    fr: { translation: fr },
  },
  lng: navigator.language.includes('fr') ? 'fr' : 'en',
  fallbackLng: navigator.language.includes('fr') ? 'fr' : 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
