import FormControl from '@mui/material/FormControl'
import { nabuColors } from '@/theme/colors'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import useTranslate from '@/hooks/use-translate'
import useUserLanguage from '@/utils/userLanguage'
import MenuItem from '@mui/material/MenuItem'
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter'
import { CircleFlag } from 'react-circle-flags'

interface LanguageEnumOptionsProps {
  label: string
  value: string
}

/**
 * SelectMenuLanguage: component to selectLanguage in the Login Page
 * @returns
 */
const SelectMenuLanguage = () => {
  const { t: tCommon } = useTranslate('common')
  const { i18n } = useTranslation()
  const languageEnum = useUserLanguage()

  /**
   * Function to render MenuItem in Select Material UI
   * @returns
   */
  const selectMenuItemLanguage = () => {
    return languageEnum.map((option: LanguageEnumOptionsProps) => (
      <MenuItem
        key={option.value}
        value={option.value}
        data-testid={`menu-item-${option.value}`}
      >
        <CircleFlag
          height="18"
          style={{ marginRight: '8px' }}
          countryCode={option.value === 'en' ? 'gb' : option.value}
        />
        <div>{capitalizeFirstLetter(option.value)}</div>
      </MenuItem>
    ))
  }

  /**
   * Function to handle the change of language in Select Material UI
   * @param event
   */
  const handleChangeUserLanguageLogin = (event: SelectChangeEvent) => {
    switch (event.target.value) {
      case 'fr': {
        i18n.changeLanguage('fr')
        localStorage.setItem('language', 'fr')
        break
      }
      case 'en': {
        i18n.changeLanguage('en')
        localStorage.setItem('language', 'en')
        break
      }
      default: {
        i18n.changeLanguage('fr')
        localStorage.setItem('language', 'fr')
        break
      }
    }
  }

  return (
    <FormControl sx={{ width: '95px', height: '38px' }}>
      <Select
        labelId="select-language-login"
        id="select-language-login"
        data-testid="select-language-login-page"
        value={i18n.language}
        variant="outlined"
        label={tCommon('language')}
        name="select-language-login-page"
        onChange={(e: SelectChangeEvent) => handleChangeUserLanguageLogin(e)}
        sx={{
          border: `1px solid ${nabuColors.greyColorLight}`,
          backgroundColor: `${nabuColors.whiteColor} !important`,
          color: `${nabuColors.blackColor2}`,
          height: '45px',
          '.MuiOutlinedInput-notchedOutline': { border: '0 !important' },
          '.MuiSvgIcon-root': {
            color: `${nabuColors.whiteColor}`,
          },
          '.MuiSelect-icon': {
            color: `${nabuColors.blackColor2}`,
          },
          '.MuiSelect-select': {
            display: 'flex !important',
            alignItems: 'center !important',
          },
        }}
        MenuProps={{
          sx: {
            '&& .MuiList-root': {
              padding: '0px',
            },
          },
        }}
      >
        {selectMenuItemLanguage()}
      </Select>
    </FormControl>
  )
}

export default SelectMenuLanguage
