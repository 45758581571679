import type { FC } from 'react'
import { useCallback } from 'react'
import toast from 'react-hot-toast'
import Settings04Icon from '@untitled-ui/icons-react/build/cjs/Settings04'
import useTranslate from '@/hooks/use-translate'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'

import { RouterLink } from '@/components/RouterLink'
import { useAuth } from '@/hooks/use-auth'
import { useRouter } from '@/hooks/use-router'
import { paths } from '@/paths'
import { Issuer } from '@/types/auth'
import { User } from '@/types/user'
import { PartialUser } from '@/api'
import { Logout } from '@mui/icons-material'

interface AccountPopoverProps {
  user: PartialUser
  anchorEl: null | Element
  onClose?: () => void
  open?: boolean
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, ...other } = props
  const router = useRouter()
  const auth = useAuth()

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      onClose?.()

      switch (auth.issuer) {
        case Issuer.JWT: {
          await auth.signOut()
          break
        }

        default: {
          console.warn('Using an unknown Auth Issuer, did not log out')
        }
      }

      router.push(paths.auth.jwt.login)
    } catch (err) {
      console.error(err)
      toast.error('Something went wrong!')
    }
  }, [auth, router, onClose])

  const { t } = useTranslate('nav')
  const { t: tCommon } = useTranslate('common')

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body2">
          {auth.user?.profile?.firstName + ' ' + auth.user?.profile?.lastName}
        </Typography>
        <Typography color="text.secondary" variant="caption">
          {auth.user?.email}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}>
        <ListItemButton
          component={RouterLink}
          href={paths.dashboard.account}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <Settings04Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body2">{t('account')}</Typography>}
          />
        </ListItemButton>
        <Divider sx={{ my: '10px !important' }} />
        <ListItemButton
          onClick={() => void handleLogout()}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2">{tCommon('logout')}</Typography>
            }
          />
        </ListItemButton>
      </Box>
    </Popover>
  )
}
