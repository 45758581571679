import type { FC, ReactNode } from 'react'
import { useCallback, useState } from 'react'
import { ChevronDown, ChevronRight } from '@untitled-ui/icons-react'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Collapse from '@mui/material/Collapse'
import SvgIcon from '@mui/material/SvgIcon'
import { RouterLink } from '@/components/RouterLink'

interface SideNavItemProps {
  active?: boolean
  children?: ReactNode
  depth?: number
  disabled?: boolean
  external?: boolean
  icon?: ReactNode
  label?: ReactNode
  badgeLabel?: number
  open?: boolean
  path?: string
  title: string
  isFolded?: boolean
}

export const SideNavItem: FC<SideNavItemProps> = (props) => {
  const {
    active,
    children,
    depth = 0,
    disabled,
    external,
    icon,
    label,
    badgeLabel,
    open: openProp,
    path,
    title,
    isFolded,
  } = props
  const [open, setOpen] = useState<boolean>(true)

  const handleToggle = useCallback((): void => {
    setOpen((prevOpen) => !prevOpen)
  }, [])

  let startIcon: ReactNode
  const offset = depth === 0 ? 0 : (depth - 1) * 16
  if (depth === 0) {
    startIcon = icon
  } else {
    if (isFolded)
      startIcon = (
        <ButtonBase
          disabled={disabled}
          onClick={handleToggle}
          title={title}
          sx={{
            alignItems: 'center',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            pl: isFolded ? undefined : `${32 + offset}px`,
            pr: '0px',
            py: isFolded ? '' : '0px',
            textAlign: 'left',
            width: '100%',
            ...(depth === 0 && {
              backgroundColor: 'var(--nav-item-active-bg)',
            }),
            '&:hover': {
              backgroundColor: isFolded ? '' : 'var(--nav-item-hover-bg)',
            },
          }}
        >
          {icon}
        </ButtonBase>
      )
    else
      startIcon = (
        <ButtonBase
          disabled={disabled}
          onClick={handleToggle}
          title={title}
          sx={{
            alignItems: 'center',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            pl: isFolded ? undefined : `${32 + offset}px`,
            pr: '0px',
            py: isFolded ? '' : '0px',
            textAlign: 'left',
            width: '100%',
            ...(depth === 0 && {
              backgroundColor: 'var(--nav-item-active-bg)',
            }),
            '&:hover': {
              backgroundColor: isFolded ? '' : 'var(--nav-item-hover-bg)',
            },
          }}
        >
          <span style={{ fontSize: '24px' }}>•</span>
        </ButtonBase>
      )
  }

  // Branch

  if (children) {
    return (
      <li>
        {!isFolded && (
          <>
            <ButtonBase
              disabled={disabled}
              onClick={handleToggle}
              title={title}
              sx={{
                alignItems: 'center',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'flex-start',
                pl: isFolded ? undefined : `${16 + offset}px`,
                pr: '16px',
                py: '6px',
                textAlign: 'left',
                width: '100%',
                ...(active && {
                  ...(depth === 0 && {
                    backgroundColor: 'var(--nav-item-active-bg)',
                  }),
                }),
                '&:hover': {
                  backgroundColor: 'var(--nav-item-hover-bg)',
                },
              }}
            >
              {startIcon && (
                <Box
                  component="span"
                  sx={{
                    alignItems: 'center',
                    color: 'var(--nav-item-icon-color)',
                    justifyContent: 'center',
                    mr: 2,
                    ...(active && {
                      color: 'var(--nav-item-icon-active-color)',
                    }),
                  }}
                >
                  {startIcon}
                </Box>
              )}
              <Box
                component="span"
                sx={{
                  color: 'var(--nav-item-color)',
                  flexGrow: 1,
                  fontFamily: (theme) => theme.typography.fontFamily,
                  fontSize: depth > 0 ? 13 : 14,
                  fontWeight: depth > 0 ? 500 : 600,
                  lineHeight: '24px',
                  whiteSpace: 'nowrap',
                  ...(active && {
                    color: 'var(--nav-item-active-color)',
                  }),
                  ...(disabled && {
                    color: 'var(--nav-item-disabled-color)',
                  }),
                }}
              >
                {title}
              </Box>
              <SvgIcon
                sx={{
                  color: 'var(--nav-item-chevron-color)',
                  fontSize: 16,
                  ml: 2,
                }}
              >
                {open ? <ChevronDown /> : <ChevronRight />}
              </SvgIcon>
            </ButtonBase>
          </>
        )}

        <Collapse in={open} sx={{ mt: 0.5 }}>
          {children}
        </Collapse>
      </li>
    )
  }

  // Leaf

  const linkProps = path
    ? external
      ? {
          component: 'a',
          href: path,
          target: '_blank',
        }
      : {
          component: RouterLink,
          href: path,
        }
    : {}

  return (
    <li>
      <ButtonBase
        disabled={disabled}
        title={title}
        sx={{
          alignItems: 'center',
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'flex-start',
          pl: `${16 + offset}px`,
          pr: '16px',
          py: '6px',
          textAlign: 'left',

          width: '100%',
          ...(active && {
            ...(depth === 0 && {
              backgroundColor: 'var(--nav-item-active-bg)',
            }),
          }),
          '&:hover': {
            backgroundColor: 'var(--nav-item-hover-bg)',
          },
        }}
        {...linkProps}
      >
        {startIcon && (
          <Box
            component="span"
            sx={{
              alignItems: 'center',
              color: 'var(--nav-item-icon-color)',
              display: 'inline-flex',
              justifyContent: 'center',
              visibility:
                isFolded || (!isFolded && (depth === 0 || active))
                  ? 'visible'
                  : 'hidden',

              mr: 2,
              ...(active && {
                color: 'var(--nav-item-icon-active-color)',
              }),
            }}
          >
            {startIcon}
          </Box>
        )}
        {!isFolded && (
          <Box
            component="span"
            sx={{
              color: 'var(--nav-item-color)',
              flexGrow: 1,
              fontFamily: (theme) => theme.typography.fontFamily,
              fontSize: depth > 0 ? 13 : 14,
              fontWeight: depth > 0 ? 500 : 600,
              lineHeight: '24px',
              whiteSpace: 'nowrap',
              ...(active && {
                color: 'var(--nav-item-active-color)',
              }),
              ...(disabled && {
                color: 'var(--nav-item-disabled-color)',
              }),
            }}
          >
            {title}
            {title === 'A traiter' && (
              <Badge
                color="primary"
                badgeContent={badgeLabel}
                sx={{ ml: 3 }}
              ></Badge>
            )}
          </Box>
        )}
        {label && (
          <Box component="span" sx={{ ml: 2 }}>
            {label}
          </Box>
        )}
      </ButtonBase>
    </li>
  )
}
