import { Flux } from '@/api'
import { FC, UIEventHandler, useState } from 'react'
import useTranslate from '@/hooks/use-translate'
import { FormikContextType } from 'formik'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import DialogUploadValuesv2 from '@/types/dialogUploadValuesv2'

interface WorkflowSectionInputProps {
  name: string
  optionsWorkflow: Flux[]
  handleInputChange: (_event: any, newInputValue: string) => void
  formikWorkflowSectionInput: FormikContextType<DialogUploadValuesv2>
  loadMoreOptions: () => Promise<void>
  hasMoreResults: boolean
}

export const WorkflowSectionInput: FC<WorkflowSectionInputProps> = (props) => {
  const {
    name,
    optionsWorkflow,
    handleInputChange,
    formikWorkflowSectionInput,
    loadMoreOptions,
    hasMoreResults,
  } = props
  const { t: tUpload } = useTranslate('upload')

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleScroll: UIEventHandler<HTMLElement> = (event) => {
    const listboxNode = event.currentTarget
    if (
      listboxNode.scrollTop + listboxNode.clientHeight ===
      listboxNode.scrollHeight
    ) {
      setIsLoading(true)
      loadMoreOptions().finally(() => setIsLoading(false))
    }
  }
  return (
    <>
      <Autocomplete
        options={optionsWorkflow}
        value={
          optionsWorkflow.find(
            (option) =>
              option.fid === formikWorkflowSectionInput.values.workflow
          ) || null
        }
        getOptionLabel={(option: Flux) => option?.name || ''}
        isOptionEqualToValue={(option, value) => option?.fid === value}
        getOptionDisabled={(option) => option.name === ''}
        id="workflow"
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={tUpload('workflow')}
            error={
              !!(
                formikWorkflowSectionInput.touched.workflow &&
                formikWorkflowSectionInput.errors.workflow
              )
            }
            helperText={
              (formikWorkflowSectionInput.touched.workflow &&
                formikWorkflowSectionInput.errors.workflow) ||
              ''
            }
            variant="outlined"
          />
        )}
        onChange={(_event, newValue) => {
          formikWorkflowSectionInput.setFieldValue(
            name,
            newValue ? newValue.fid : ''
          )
        }}
        ListboxProps={{
          onScroll: handleScroll,
          style: { maxHeight: '200px', overflow: 'auto' },
        }}
        data-testid="workflow-autocomplete"
      />
      {/*isLoading && <CircularProgress />*/}
    </>
  )
}

export default WorkflowSectionInput
