import { Configuration, Flux, searchFlux } from '@/api'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import useTranslate from '@/hooks/use-translate'
import { useFormikContext } from 'formik'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { useQuery } from '@tanstack/react-query'
import debounce from 'lodash/debounce'
import DialogUploadValuesv2 from '@/types/dialogUploadValuesv2'
import WorkflowSectionInput from '../WorkflowSectionInput'
import ConfigurationsSectionInput from '../ConfigurationsSectionInput'
import determineDeclarationType from './utils/determineDeclarationType'
import { nabuColors, neutral } from '@/theme/colors'

interface FormControlConfigurationsWorkflowProps {
  configurations: Configuration[]
  groupId: string
  name: keyof DialogUploadValuesv2
  values: DialogUploadValuesv2
  checkedCheckbox: boolean
  setSelectTypeDeclarationValue: Dispatch<SetStateAction<string>>
}

/**
 * FormControlConfigurationsWorkflow is a component that manages the selection of configurations and workflows within a form.
 * It dynamically filters and displays options based on the user's group selection and other form inputs.
 *
 * @param {FormControlConfigurationsWorkflowProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered FormControlConfigurationsWorkflow component.
 */
export const FormControlConfigurationsWorkflow: FC<
  FormControlConfigurationsWorkflowProps
> = (props) => {
  const SIZE_OF_RESPONSE = 10
  const {
    configurations,
    groupId,
    name,
    values,
    checkedCheckbox,
    setSelectTypeDeclarationValue,
  } = props
  const { t: tUpload } = useTranslate('upload')

  const [optionsWorkflow, setOptionsWorkflow] = useState<Flux[]>([])
  const [filterOptionsWorkflow, setFilterOptionsWorkflow] = useState<Flux[]>([])
  const [currentSelectedConfigurationId, setCurrentSelectedConfigurationId] =
    useState<string>('')
  const [page, setPage] = useState<number>(0)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [hasMoreResults, setHasMoreResults] = useState<boolean>(true)

  const { data: workflowData } = useQuery({
    queryKey: ['Workflow', values.group],
    queryFn: () =>
      searchFlux({ groupId: values.group, size: SIZE_OF_RESPONSE, page: 0 }),
    enabled: !!values.group,
  })
  const formikWorkflowSectionInput = useFormikContext<DialogUploadValuesv2>()

  /**
   * Effect to filter workflows based on the selected configuration.
   */
  useEffect(() => {
    if (currentSelectedConfigurationId.length > 0) {
      const selectedConfiguration = configurations.find(
        (value) => value.cid === currentSelectedConfigurationId
      )
      if (selectedConfiguration) {
        const selectDeclarationType = determineDeclarationType({
          name: selectedConfiguration.name,
        })
        const filterOptionsWorkflowArr = optionsWorkflow.filter(
          (option) => option.declarationType === selectDeclarationType
        )
        setFilterOptionsWorkflow(filterOptionsWorkflowArr)
      }
    }
  }, [configurations, currentSelectedConfigurationId, optionsWorkflow])

  /**
   * Handles input change in the workflow search input, fetching workflows based on user input.
   */
  const handleInputChange = debounce((_event, newInputValue) => {
    if (values.group) {
      searchFlux({
        name: newInputValue,
        groupId: values.group,
        page: 0,
        size: SIZE_OF_RESPONSE,
      })
        .then((results) => {
          const workflowOfGroup =
            results?.data?.filter((el) => el.groupId === groupId) ?? []
          setOptionsWorkflow(workflowOfGroup)
          setFilterOptionsWorkflow(workflowOfGroup)
          setPage(1) // Reset to the second page for further scrolling
          setHasMoreResults(workflowOfGroup.length >= SIZE_OF_RESPONSE) // Check if there are more results available
        })
        .catch((e) => console.log('error searchFlux', e))
    }
  }, 500)

  /**
   * Retrieves the workflows associated with the selected group.
   */
  const getWorkFlowOfGroup = () => {
    const workflowOfGroup =
      workflowData?.data?.filter((el) => el.groupId === groupId) || []
    setOptionsWorkflow(workflowOfGroup || [])
    setFilterOptionsWorkflow(workflowOfGroup)
    setPage(1) // Reset to the second page for further scrolling
    setHasMoreResults(workflowOfGroup.length >= SIZE_OF_RESPONSE) // Check if there are more results available
  }

  /**
   * Effect to fetch and set workflows when the group data changes.
   */
  useEffect(() => {
    getWorkFlowOfGroup()
  }, [workflowData, groupId])

  /**
   * Effect to reset the field value if the group changes.
   */
  useEffect(() => {
    if (
      formikWorkflowSectionInput.values.group &&
      formikWorkflowSectionInput.values[name] !== ''
    ) {
      formikWorkflowSectionInput.setFieldValue(name, '')
    }
  }, [formikWorkflowSectionInput.values.group, name])

  /**
   * Loads more workflow options when the user scrolls down.
   */
  const loadMoreOptions = async () => {
    if (isFetching || !hasMoreResults) return // Prevent multiple fetches at the same time or if no more results

    setIsFetching(true)
    try {
      const moreResults = await searchFlux({
        groupId: values.group,
        page,
        size: SIZE_OF_RESPONSE,
      })
      const moreOptions = moreResults?.data || []
      setOptionsWorkflow((prevOptions) => [...prevOptions, ...moreOptions])
      setFilterOptionsWorkflow((prevOptions) => [
        ...prevOptions,
        ...moreOptions,
      ])
      setPage((prevPage) => prevPage + 1)
      setHasMoreResults(moreOptions.length >= SIZE_OF_RESPONSE) // Check if there are more results available
    } catch (error) {
      console.log('Error loading more options:', error)
    } finally {
      setIsFetching(false)
    }
  }

  return (
    <>
      <FormControl
        variant="outlined"
        sx={{ m: 1, width: '100%' }}
        data-testid="formcontrol-configurations-workflow"
      >
        <InputLabel
          id="export-service-select-label"
          sx={{
            // When the checkbox Fast track is checked, the color change
            color: checkedCheckbox
              ? `${nabuColors.greenCyan}`
              : `{${neutral[200]}}`,
          }}
        >
          {tUpload('declarationType')}
        </InputLabel>
        <ConfigurationsSectionInput
          name="exportConfiguration"
          configurations={
            configurations?.filter((el) => el.groupId === values.group) || []
          }
          setCurrentSelectedConfigurationId={setCurrentSelectedConfigurationId}
          optionsWorkflow={optionsWorkflow}
          setFilterOptionsWorkflow={setFilterOptionsWorkflow}
          getWorkFlowOfGroup={getWorkFlowOfGroup}
          checkedCheckbox={checkedCheckbox}
          setSelectTypeDeclarationValue={setSelectTypeDeclarationValue}
        />
      </FormControl>
      <FormControl variant="standard" sx={{ m: 1, width: '100%' }}>
        <WorkflowSectionInput
          name="workflow"
          optionsWorkflow={filterOptionsWorkflow}
          handleInputChange={handleInputChange}
          formikWorkflowSectionInput={formikWorkflowSectionInput}
          loadMoreOptions={loadMoreOptions}
          hasMoreResults={hasMoreResults}
        />
      </FormControl>
    </>
  )
}

export default FormControlConfigurationsWorkflow
