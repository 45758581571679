import ReactDOM from 'react-dom/client'
import { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { App } from './app'
// import './tracing'
import withClearCache from './withClearCache'
import Zendesk from './utils/zendeskConfig'
import { defaultConfig } from './config'

const ClearCacheApp = withClearCache(App)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const ZENDESK_KEY = defaultConfig().zendeskApiKey

root.render(
  <HelmetProvider>
    <Zendesk defer zendeskKey={ZENDESK_KEY} />
    <BrowserRouter>
      <Suspense>
        <ClearCacheApp />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
)
