import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import { FC } from 'react'
import { useFormikContext } from 'formik'
import DialogUploadValuesv2 from '@/types/dialogUploadValuesv2'
import getFieldTestId from '@/utils/getFieldTestId'
import useTranslate from '@/hooks/use-translate'

interface FolderNameSectionInputProps {
  /**
   * Name of the field
   */
  name: keyof DialogUploadValuesv2
}

export const FolderNameSectionInput: FC<FolderNameSectionInputProps> = ({
  name,
}) => {
  // We avoid passing formik in props to avoid re-rendering
  const formik = useFormikContext<DialogUploadValuesv2>()
  const { t: tCommon } = useTranslate('common')

  return (
    <>
      <TextField
        label={tCommon('fileName')}
        required
        sx={{ flexGrow: 1 }}
        inputProps={{ ...getFieldTestId(name), maxLength: 32 }}
        error={
          (formik.values.files.length > 0 &&
            Boolean(formik.errors.recordName)) ||
          (formik.touched.recordName && Boolean(formik.errors.recordName))
        }
        helperText={
          (formik.values.files.length > 0 && formik.errors.recordName) ||
          (formik.touched.recordName && formik.errors.recordName)
        }
        {...formik.getFieldProps(name)}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{ visibility: formik.values[name] ? 'visible' : 'hidden' }}
              onClick={() => formik.setFieldValue(name, '')}
              data-testid="clear-button"
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
    </>
  )
}

export default FolderNameSectionInput
