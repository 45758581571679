import type { FC, ReactNode } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { SideNavItem } from './side-nav-item'

interface Item {
  disabled?: boolean
  external?: boolean
  icon?: ReactNode
  items?: Item[]
  label?: ReactNode
  badgeLabel?: number
  path?: string
  title: string
}

const renderItems = ({
  depth = 0,
  items,
  pathname,
  isFolded,
}: {
  depth?: number
  items: Item[]
  pathname?: string | null
  isFolded?: boolean
}): JSX.Element[] =>
  items.reduce(
    (acc: JSX.Element[], item) =>
      reduceChildRoutes({
        acc,
        depth,
        item,
        pathname,
        isFolded,
      }),
    []
  )

const reduceChildRoutes = ({
  acc,
  depth,
  item,
  pathname,
  isFolded,
}: {
  acc: JSX.Element[]
  depth: number
  item: Item
  pathname?: string | null
  isFolded?: boolean
}): Array<JSX.Element> => {
  const checkPath = !!(item.path && pathname)
  const partialMatch = checkPath ? pathname.includes(item.path!) : false
  const exactMatch = checkPath ? pathname === item.path : false

  if (item.items) {
    acc.push(
      <SideNavItem
        active={partialMatch}
        depth={depth}
        badgeLabel={item.badgeLabel}
        disabled={item.disabled}
        icon={item.icon}
        key={item.title}
        label={item.label}
        open={partialMatch}
        title={item.title}
        isFolded={isFolded}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: 'none',
            m: 0,
            p: 0,
          }}
        >
          {renderItems({
            depth: depth + 1,
            items: item.items,
            pathname,
            isFolded,
          })}
        </Stack>
      </SideNavItem>
    )
  } else {
    acc.push(
      <SideNavItem
        active={exactMatch}
        depth={depth}
        disabled={item.disabled}
        external={item.external}
        icon={item.icon}
        key={item.title}
        label={item.label}
        badgeLabel={item.badgeLabel}
        path={item.path}
        title={item.title}
        isFolded={isFolded}
      />
    )
  }

  return acc
}

interface SideNavSectionProps {
  items?: Item[]
  pathname?: string | null
  subheader?: string
  isFolded?: boolean
}

export const SideNavSection: FC<SideNavSectionProps> = (props) => {
  const {
    items = [],
    pathname,
    subheader = '',
    isFolded = false,
    ...other
  } = props

  return (
    <Stack
      component="ul"
      spacing={0.5}
      sx={{
        listStyle: 'none',
        m: 0,
        p: 0,
      }}
      {...other}
    >
      {!isFolded && subheader && (
        <Box
          component="li"
          sx={{
            color: 'var(--nav-section-title-color)',
            fontSize: 12,
            fontWeight: 700,
            lineHeight: 1.66,
            mb: 1,
            ml: 1,
            textTransform: 'uppercase',
          }}
        >
          {subheader}
        </Box>
      )}
      {renderItems({ items, pathname, isFolded })}
    </Stack>
  )
}
