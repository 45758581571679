import type { ReactNode } from 'react'
import { useMemo } from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import HomeSmileIcon from '@/icons/untitled-ui/duocolor/home-smile'
import Inbox01Icon from '@untitled-ui/icons-react/build/esm/Inbox01'
import Settings04Icon from '@untitled-ui/icons-react/build/esm/Settings04'
import { paths } from '@/paths'
import useTranslate from '@/hooks/use-translate'
import {
  Mail01,
  Send01,
  Users03,
  Building07,
  LayersThree01,
  Link04,
  Database02,
  Shuffle01,
  BarChart10,
} from '@untitled-ui/icons-react'
import { useAuth } from '@/hooks/use-auth'
import { Role } from '@/api'

export interface Item {
  disabled?: boolean
  external?: boolean
  icon?: ReactNode
  items?: Item[]
  label?: ReactNode
  path?: string
  title: string
}

export interface Section {
  items: Item[]
  subheader?: string
}

export const useSections = () => {
  const { t: tNav } = useTranslate('nav')
  const { user } = useAuth()

  return useMemo(() => {
    const sections: any[] = [
      {
        items: [
          {
            title: tNav('dashboard'),
            path: paths.dashboard.index,
            badgeLabel: undefined,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },
          {
            title: tNav('fieldList'),
            path: paths.dashboard.documents.index,
            badgeLabel: undefined,
            icon: (
              <SvgIcon fontSize="small">
                <Inbox01Icon />
              </SvgIcon>
            ),
            items: [
              {
                title: tNav('pendingField'),
                path: paths.dashboard.documents.manage,
                badgeLabel: undefined,
                hiddenIcon: true,
                icon: (
                  <SvgIcon fontSize="small">
                    <Mail01 />
                  </SvgIcon>
                ),
              },
              {
                title: tNav('fieldExported'),
                path: paths.dashboard.documents.exported,
                badgeLabel: undefined,
                icon: (
                  <SvgIcon fontSize="small">
                    <Send01 />
                  </SvgIcon>
                ),
              } /*
              {
                title: tNav('fieldDeleted'),
                path: paths.dashboard.documents.deleted,
                badgeLabel: undefined,
                icon: (
                  <SvgIcon fontSize="small">
                    <Trash01 />
                  </SvgIcon>
                ),
              },*/,
            ],
          },

          {
            title: tNav('customerFlow'),
            path: paths.dashboard.customerFlow.index,
            badgeLabel: undefined,
            icon: (
              <SvgIcon fontSize="small">
                <Database02 />
              </SvgIcon>
            ),
            items: [
              // {
              //   title: tNav('customer'),
              //   path: paths.dashboard.customerFlow.customer,
              //   badgeLabel: undefined,
              //   hiddenIcon: true,
              //   icon: (
              //     <SvgIcon fontSize="small">
              //       <UserPlus01 />
              //     </SvgIcon>
              //   ),
              // },
              {
                title: tNav('flow'),
                path: paths.dashboard.customerFlow.flow,
                badgeLabel: undefined,
                hiddenIcon: true,
                icon: (
                  <SvgIcon fontSize="small">
                    <Shuffle01 />
                  </SvgIcon>
                ),
              },
            ],
          },
        ],
      },
      {
        items: [
          {
            title: tNav('account'),
            path: paths.dashboard.account,
            badgeLabel: undefined,
            icon: (
              <SvgIcon fontSize="small">
                <Settings04Icon />
              </SvgIcon>
            ),
          },
        ],
      },
    ]

    /* Management Console */
    /* Uniquement superadmin, admin ou manager */
    if (
      user?.role === Role.Superadmin ||
      user?.role === Role.Admin ||
      user?.role === Role.Manager
    ) {
      sections.push({
        items: [
          {
            title: tNav('managementConsole'),
            path: paths.dashboard.managementConsole.index,
            badgeLabel: undefined,
            icon: (
              <SvgIcon fontSize="small">
                <BarChart10 />
              </SvgIcon>
            ),
          },
        ],
      })
    }

    if (user?.role === Role.Superadmin) {
      sections.push({
        subheader: tNav('backoffice'),
        items: [
          {
            title: tNav('users'),
            path: paths.backoffice.users.index,
            badgeLabel: undefined,
            icon: (
              <SvgIcon fontSize="small">
                <Users03 />
              </SvgIcon>
            ),
          },
          {
            title: tNav('organizations'),
            path: paths.backoffice.organizations.index,
            badgeLabel: undefined,
            icon: (
              <SvgIcon fontSize="small">
                <Building07 />
              </SvgIcon>
            ),
          },
          {
            title: tNav('layers'),
            path: paths.backoffice.layers.index,
            badgeLabel: undefined,
            icon: (
              <SvgIcon fontSize="small">
                <LayersThree01 />
              </SvgIcon>
            ),
          },
          {
            title: tNav('connectors'),
            path: paths.backoffice.connectors.index,
            badgeLabel: undefined,
            icon: (
              <SvgIcon fontSize="small">
                <Link04 />
              </SvgIcon>
            ),
          },
        ],
      })
    }

    return sections
  }, [tNav])
}
