import { Dispatch, FC, SetStateAction } from 'react'
import {
  DropzoneInputProps,
  DropzoneRootProps,
  FileWithPath,
} from 'react-dropzone'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import { Plus } from '@untitled-ui/icons-react'
import { nabuColors } from '@/theme/colors'

interface FileThumbnailInputProps {
  /**
   * Whether the dropzone is active
   */
  isDragActive: boolean
  /**
   * Root props of thz dropzone
   */
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
  /**
   * Input props of the dropzone
   */
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T

  dataTestId?: string
}

/**
 * The FileThumbnailInput component renders a dropzone for file uploads.
 * It provides a visual area where users can drag and drop files, or click to select files from their device.
 * The component also manages the state of the files selected through the dropzone.
 *
 * @param {FileThumbnailInputProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered FileThumbnailInput component.
 */
export const FileThumbnailInput: FC<FileThumbnailInputProps> = (props) => {
  const { isDragActive, getRootProps, getInputProps, dataTestId } = props

  return (
    <Box
      sx={{
        alignItems: 'center',
        border: 2,
        borderRadius: 1,
        borderStyle: 'dashed',
        backgroundColor: 'transparent',
        borderColor: `${nabuColors.greenCyan}`,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        outline: 'none',
        p: 4,
        ...(isDragActive && {
          backgroundColor: 'action.active',
          opacity: 0.5,
        }),
        '&:hover': {
          backgroundColor: 'action.hover',
          cursor: 'pointer',
          opacity: 0.8,
        },
        width: 180,
        height: '100%',
        minheight: '565px',
      }}
      {...getRootProps()}
      data-testid={dataTestId}
    >
      <input
        {...getInputProps()}
        multiple={false}
        accept=".pdf, .jpeg, .jpg, .png, .xls, .xlsx, .ods"
      />
      <Stack alignItems="center" direction="row" spacing={2}>
        <Avatar
          sx={{
            height: 32,
            width: 32,
            color: `${nabuColors.whiteColor}`,
            backgroundColor: `${nabuColors.greenCyan}`,
          }}
        >
          <SvgIcon>
            <Plus />
          </SvgIcon>
        </Avatar>
      </Stack>
    </Box>
  )
}

export default FileThumbnailInput
