import { Group, Organization } from '@/api'
import DialogUploadValuesv2 from '@/types/dialogUploadValuesv2'
import getFieldTestId from '@/utils/getFieldTestId'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useFormikContext } from 'formik'
import { FC, useEffect, useState } from 'react'

interface GroupSectionInputProps {
  /**
   * Current organization
   */
  currentOrganization?: Organization
  /**
   * Name of the field
   */
  name: string
  /** Set Current Group selected */
  myGroups?: Group[]
}

/**
 * GroupSectionInput is a form field component that allows users to select a group from a dropdown list.
 * It uses Formik for form state management and updates the form field with the selected group.
 *
 * @param {GroupSectionInputProps} props - The properties passed to the component.
 * @returns {JSX.Element | null} The rendered GroupSectionInput component or null if no groups are available.
 */
export const GroupSectionInput: FC<GroupSectionInputProps> = ({
  name,
  myGroups,
}) => {
  // We avoid passing formik in props to avoid re-rendering
  const formik = useFormikContext<DialogUploadValuesv2>()

  /**
   * Effect to set the initial value of the group field if `myGroups` is available.
   * Sets the first group's ID as the default value if available.
   */
  useEffect(() => {
    if (myGroups && myGroups.length > 0) {
      const firstGroupId = myGroups[0].gid
      if (firstGroupId) {
        formik.setFieldValue(name, firstGroupId)
      } else {
        console.log(`Group ${firstGroupId ?? ''} not found in options.`)
      }
    }
  }, [myGroups])

  if (!myGroups?.length) {
    return null
  }

  return (
    <Select
      variant="outlined"
      size="small"
      error={formik.touched.group && Boolean(formik.errors.group)}
      inputProps={{ ['data-testid']: 'group-input' }}
      disabled={myGroups.length == 0}
      {...formik.getFieldProps(name)}
    >
      {myGroups?.map((group: Group, index) => (
        <MenuItem
          key={group.gid}
          value={group.gid}
          {...getFieldTestId(`${name}-option-${index}`)}
        >
          {group.name}
        </MenuItem>
      ))}
    </Select>
  )
}

export default GroupSectionInput
