export const uploadFile = async (
  signedUrl: string,
  file: File
): Promise<void> => {
  /*const data = new FormData()
  data.append('file', file)*/

  try {
    await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    })
  } catch (error: any) {
    console.error(`Error in file upload: ${error.message}`)
    throw error
  }
}

export default uploadFile
