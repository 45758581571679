import { nabuColors } from '@/theme/colors'
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter'
import Chip from '@mui/material/Chip'

import { FC } from 'react'

interface ExportTypeChipProps {
  exportType?: 'export' | 'import' | 'transit'
  size?: 'small' | 'medium'
}

const ExportTypeChip: FC<ExportTypeChipProps> = ({
  exportType,
  size = 'medium',
}) => {
  if (!exportType) return <></>
  //test
  return (
    exportType && (
      <Chip
        label={capitalizeFirstLetter(exportType)}
        size={size}
        color="info"
        sx={{
          alignItems: 'center',
          alignContent: 'center',
          backgroundColor:
            exportType && exportType.toLowerCase() === 'import'
              ? `${nabuColors.blueMajorelle} !important`
              : exportType && exportType.toLowerCase() === 'export'
              ? `${nabuColors.greenEmeraude} !important`
              : `${nabuColors.ambre} !important`,
        }}
      />
    )
  )
}

export default ExportTypeChip
