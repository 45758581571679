import type { FC } from 'react'
import { AuthGuard } from '@/guards/auth-guard'
import { Role } from '@/api'

export const withAuthGuard =
  <P extends object>(Component: FC<P>, role: Role[] | null): FC<P> =>
  (props: P) =>
    (
      <AuthGuard role={role}>
        <Component {...props} />
      </AuthGuard>
    )
