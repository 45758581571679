export const wait = (time: number): Promise<void> =>
  new Promise((res) => setTimeout(res, time))

export async function delayOrExecute<T>(
  promise: Promise<T>,
  delayInMs: number
): Promise<T> {
  let errorOccurred = false
  let result: T | null = null
  let error: any = null

  const startTime = Date.now()

  try {
    result = await promise
  } catch (err) {
    error = err
    errorOccurred = true
  }

  const endTime = Date.now()
  const executionTime = endTime - startTime
  const remainingDelay = delayInMs - executionTime

  // Wait for the remaining time if the operation was quicker than the delay.
  if (remainingDelay > 0) {
    await new Promise((resolve) => setTimeout(resolve, remainingDelay))
  }

  // If an error occurred, throw it after the delay.
  if (errorOccurred) {
    throw error
  }

  return result as T
}
