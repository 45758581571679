import type { FC } from 'react'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'

interface LoadingLogoProps {
  width?: number
  height?: number
}

const LogoContainer = styled('div')<LoadingLogoProps>`
  display: inline-block;
  width: ${({ width = 48 }) => `${width}px`};
  height: ${({ height = 48 }) => `${height}px`};
  animation: rotation 2s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`

export const LoadingLogo: FC<LoadingLogoProps> = ({
  width = 48,
  height = 48,
}) => {
  const theme = useTheme()

  return (
    <LogoContainer width={width} height={height}>
      <img
        src="/assets/nabu-logo.png"
        className="logo nabu"
        alt="Nabu logo"
        style={{ width: '100%', height: '100%' }}
      />
    </LogoContainer>
  )
}
