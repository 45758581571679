export const getColor = (str: string): string => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    color += ('00' + value.toString(16)).substr(-2)
  }

  // Si la luminosité est trop proche du blanc, on la fonce pour améliorer le contraste
  if (!hasSufficientContrast(color, '#FFFFFF')) {
    color = darkenColor(color, 30) // Assombrir la couleur de fond de 30% si le contraste est insuffisant
  }

  return color
}

// Fonction pour vérifier le contraste entre deux couleurs en utilisant la luminance relative
function hasSufficientContrast(bgColor: string, textColor: string): boolean {
  const contrastRatio = getContrastRatio(bgColor, textColor)
  return contrastRatio >= 4.5 // Un ratio >= 4.5 est généralement considéré comme bon pour l'accessibilité
}

// Calculer le rapport de contraste entre deux couleurs (selon les recommandations WCAG)
function getContrastRatio(color1: string, color2: string): number {
  const luminance1 = getLuminance(color1)
  const luminance2 = getLuminance(color2)

  return (
    (Math.max(luminance1, luminance2) + 0.05) /
    (Math.min(luminance1, luminance2) + 0.05)
  )
}

// Calculer la luminance d'une couleur hexadécimale
function getLuminance(hex: string): number {
  const rgb = hexToRgb(hex).map((value) => {
    value /= 255
    return value <= 0.03928
      ? value / 12.92
      : Math.pow((value + 0.055) / 1.055, 2.4)
  })

  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]
}

// Convertir une couleur hexadécimale en tableau RGB
function hexToRgb(hex: string): number[] {
  return [
    parseInt(hex.slice(1, 3), 16),
    parseInt(hex.slice(3, 5), 16),
    parseInt(hex.slice(5, 7), 16),
  ]
}

// Fonction utilitaire pour assombrir une couleur en utilisant une valeur de pourcentage
function darkenColor(color: string, percentage: number): string {
  const hex = color.slice(1)
  const num = parseInt(hex, 16)
  const amount = Math.round(2.55 * percentage)

  const r = Math.max((num >> 16) - amount, 0)
  const g = Math.max(((num >> 8) & 0x00ff) - amount, 0)
  const b = Math.max((num & 0x0000ff) - amount, 0)

  return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`
}
