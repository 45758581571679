import { Organization } from '@/types/organization'
import type { User } from '@/types/user'

export const users: User[] = [
  {
    id: '5e86809283e28b96d2d38537',
    email: 'demo@nabu.io',
    password: 'nabu',
    firstName: 'George',
    lastName: 'Abitbol',
    userFunction: 'rde',
    language: 'fr',
  },
]

export const organization: Organization = {
  id: '5e86809283e28b96d2d38539',
  name: 'Nabu',
  siret: '09787787889',
  groups: [
    {
      id: '5e86809283e28b96d2d38567',
      name: 'my Team',
      members: [{ user: users[0], role: 'manager' }],
    },
  ],
}
