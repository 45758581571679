export const decode = (token: string): any => {
  const [_, encodedPayload] = token.split('Bearer')[1].trim().split('.')
  const payload = JSON.parse(atob(encodedPayload))

  return payload
}

export const decodeWithoutBearer = (token: string): any => {
  const [_, encodedPayload] = token.trim().split('.')
  const payload = JSON.parse(atob(encodedPayload))

  return payload
}
