export interface Args {
  name: string | undefined
}

const determineDeclarationType = ({ name }: Args): string | null => {
  const match = name?.toLowerCase().match(/export|import|transit/)
  return match ? match[0] : null
}

export default determineDeclarationType
