import type { FC, ReactNode } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useAuth } from '@/hooks/use-auth'
import { useRouter } from '@/hooks/use-router'
import { paths } from '@/paths'
import { toast } from 'react-hot-toast'
import useTranslate from '@/hooks/use-translate'
import { Role } from '@/api'

interface AuthGuardProps {
  children: ReactNode
  role: Role[] | null
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props
  const router = useRouter()
  const { isAuthenticated, issuer, user } = useAuth()

  const { t: tCommon } = useTranslate('common')

  const [checked, setChecked] = useState(false)

  const check = useCallback(() => {
    if (!isAuthenticated) {
      router.replace(paths.auth.jwt.login)
    } else if (props.role && !props.role.includes(user?.role as Role)) {
      router.back()
      toast.error(tCommon('permissionError'))
    } else {
      setChecked(true)
    }
  }, [isAuthenticated, issuer, router])

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(
    () => {
      check()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (!checked) {
    return null
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return <>{children}</>
}
