import { lazy, Suspense, useState } from 'react'
import type { RouteObject } from 'react-router'
import { Outlet } from 'react-router-dom'
import { IssuerGuard } from '@/guards/issuer-guard'
import { AuthGuard } from '@/guards/auth-guard'
import { Issuer } from '@/types/auth'
import { Layout as DashboardLayout } from '@/layouts/dashboard'
import { Role } from '@/api'
import { wrapWithErrorBoundary } from './wrapWithErrorBoundary'

const UsersListPage = lazy(() => import('@/pages/backoffice/users/list'))
const UserEditPage = lazy(() => import('@/pages/backoffice/users/edit'))

const OrganizationListPage = lazy(
  () => import('@/pages/backoffice/organizations/list')
)
const OrganizationEditPage = lazy(
  () => import('@/pages/backoffice/organizations/edit')
)
const GroupEditPage = lazy(
  () => import('@/pages/backoffice/organizations/groups/edit')
)

const LayersListPage = lazy(() => import('@/pages/backoffice/layers/list'))
const LayersEditPage = lazy(() => import('@/pages/backoffice/layers/edit'))
const ConnectorsListPage = lazy(
  () => import('@/pages/backoffice/connectors/list')
)

const WrappedUsersListPage = wrapWithErrorBoundary(UsersListPage)
const WrappedUserEditPage = wrapWithErrorBoundary(UserEditPage)
const WrappedOrganizationListPage = wrapWithErrorBoundary(OrganizationListPage)
const WrappedOrganizationEditPage = wrapWithErrorBoundary(OrganizationEditPage)
const WrappedGroupEditPage = wrapWithErrorBoundary(GroupEditPage)
const WrappedLayersListPage = wrapWithErrorBoundary(LayersListPage)
const WrappedLayersEditPage = wrapWithErrorBoundary(LayersEditPage)
const WrappedConnectorsListPage = wrapWithErrorBoundary(ConnectorsListPage)

const BackofficeLayoutWrapper = (props: any) => {
  const [isFullscreen, setIsFullscreen] = useState(false)

  return (
    <IssuerGuard issuer={Issuer.JWT}>
      <DashboardLayout isFullscreen={isFullscreen}>
        <AuthGuard role={[Role.Superadmin]}>
          <Suspense>
            <Outlet context={{ setIsFullscreen }} />
          </Suspense>
        </AuthGuard>
      </DashboardLayout>
    </IssuerGuard>
  )
}

export const backofficeRoutes: RouteObject[] = [
  {
    path: 'backoffice',
    element: <BackofficeLayoutWrapper />,
    children: [
      {
        path: 'users',
        children: [
          {
            index: true,
            element: <WrappedUsersListPage />,
          },
          {
            path: ':userId/edit',
            element: <WrappedUserEditPage />,
          },
        ],
      },
      {
        path: 'organizations',
        children: [
          {
            index: true,
            element: <WrappedOrganizationListPage />,
          },
          {
            path: ':organizationId/edit',
            element: <WrappedOrganizationEditPage />,
          },
          {
            path: ':organizationId/groups/:groupId/edit',
            element: <WrappedGroupEditPage />,
          },
        ],
      },
      {
        path: 'layer',
        children: [
          {
            index: true,
            element: <WrappedLayersListPage />,
          },
        ],
      },
      {
        path: 'layer/:id',
        children: [
          {
            index: true,
            element: <WrappedLayersEditPage />,
          },
        ],
      },
      {
        path: 'connectors',
        children: [
          {
            index: true,
            element: <WrappedConnectorsListPage />,
          },
        ],
      },
    ],
  },
]
