import type { FC, ReactNode } from 'react'
import { createContext, useCallback, useEffect, useReducer } from 'react'
import { authApi } from '@/api/auth'
import { Issuer } from '@/types/auth'
import { Language, LoginParameter, User } from '@/api'
import * as api from '@/api'
import { decodeWithoutBearer } from '@/utils/jwt'
import { useTranslation } from 'react-i18next'

const STORAGE_KEY = 'accessToken'

interface State {
  isInitialized: boolean
  isAuthenticated: boolean
  user: Partial<User> | null
}

enum ActionType {
  INITIALIZE = 'INITIALIZE',
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  SIGN_OUT = 'SIGN_OUT',
  UPDATE = 'UPDATE',
}

type InitializeAction = {
  type: ActionType.INITIALIZE
  payload: {
    isAuthenticated: boolean
    user: Partial<User> | null
  }
}

type SignInAction = {
  type: ActionType.SIGN_IN
  payload: {
    user: Partial<User>
  }
}

type SignUpAction = {
  type: ActionType.SIGN_UP
  payload: {
    user: Partial<User>
  }
}

type SignOutAction = {
  type: ActionType.SIGN_OUT
}

type UpdateAction = {
  type: ActionType.UPDATE
  payload: {
    user: Partial<User>
  }
}

type Action =
  | InitializeAction
  | SignInAction
  | SignUpAction
  | SignOutAction
  | UpdateAction

type Handler = (state: State, action: any) => State

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
}

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    }
  },
  SIGN_IN: (state: State, action: SignInAction): State => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user,
    }
  },
  SIGN_UP: (state: State, action: SignUpAction): State => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user,
    }
  },
  SIGN_OUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  UPDATE: (state: State, action: UpdateAction): State => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user,
    }
  },
}

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state

export interface AuthContextType extends State {
  issuer: Issuer.JWT
  signIn: (email: string, password: string) => Promise<void>
  signUp: (
    email: string,
    firstName: string,
    lastName: string,
    password: string
  ) => Promise<void>
  signOut: () => Promise<void>
  renewPassword: (
    email: string,
    newPassword: string,
    tid?: string,
    token?: string
  ) => Promise<void>
  attest: (
    email: string,
    newPassword: string,
    tid?: string,
    token?: string
  ) => Promise<void>
  lostAccess: (email: string) => Promise<void>
  updateUserContext: (user: Partial<User>) => Promise<void>
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  issuer: Issuer.JWT,
  signIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  renewPassword: () => Promise.resolve(),
  attest: () => Promise.resolve(),
  lostAccess: () => Promise.resolve(),
  updateUserContext: () => Promise.resolve(),
})

interface AuthProviderProps {
  children: ReactNode
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)

  const { i18n } = useTranslation()

  const initialize = useCallback(async (): Promise<void> => {
    try {
      const accessToken = window.localStorage.getItem(STORAGE_KEY)

      if (accessToken) {
        const decodedToken = await decodeWithoutBearer(accessToken)

        api.defaults.headers = {
          ...api.defaults.headers,
          Authorization: `Bearer ${window.localStorage.getItem(STORAGE_KEY)}`,
        }

        const user = await api.findOneUser(decodedToken.sub)
        const localStorageLanguage = localStorage.getItem('language')

        // We compare the localStorageLanguage with user.profile?.language
        // To the first initialization, the user doesn't exist yet
        if (
          localStorageLanguage !== null &&
          localStorageLanguage !== user.profile?.language
        ) {
          i18n.changeLanguage(localStorageLanguage)
        } else {
          i18n.changeLanguage(user.profile?.language)
        }

        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            user: user,
          },
        })
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        })
      }
    } catch (err) {
      console.error(err)
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      })
    }
  }, [dispatch])

  useEffect(
    () => {
      initialize()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const signIn = useCallback(
    async (email: string, password: string): Promise<void> => {
      const { accessToken } = await authApi.signIn({ email, password })
      localStorage.setItem(STORAGE_KEY, accessToken)

      const decodedToken = await decodeWithoutBearer(accessToken)

      api.defaults.headers = {
        ...api.defaults.headers,
        Authorization: `Bearer ${accessToken}`,
      }

      const user = await api.findOneUser(decodedToken.sub)

      // We compare the localStorageLanguage with user.profile?.language
      // To keep the good language select to the LoginPage
      // To the first initialization, the user doesn't exist yet
      if (i18n.language !== user.profile?.language) {
        const updateUser = {
          ...user,
          profile: {
            ...user.profile,
            language: i18n.language as Language | undefined,
          },
        }
        i18n.changeLanguage(i18n.language)
        localStorage.setItem('language', i18n.language)

        dispatch({
          type: ActionType.SIGN_IN,
          payload: {
            user: updateUser,
          },
        })
      } else {
        i18n.changeLanguage(user.profile?.language)
        localStorage.setItem('language', i18n.language)

        dispatch({
          type: ActionType.SIGN_IN,
          payload: {
            user: user,
          },
        })
      }
    },
    [dispatch]
  )

  const signUp = useCallback(
    async (
      email: string,
      firstName: string,
      lastName: string,
      password: string
    ): Promise<void> => {
      const { accessToken } = await authApi.signUp({
        email,
        firstName,
        lastName,
        password,
      })
      const user = await authApi.me({ accessToken })

      localStorage.setItem(STORAGE_KEY, accessToken)

      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user,
        },
      })
    },
    [dispatch]
  )

  const renewPassword = useCallback(
    async (
      email: string,
      renewPassword: string,
      tid?: string,
      token?: string
    ): Promise<void> => {
      const login: LoginParameter = {
        email: email,
        password: renewPassword,
      }
      await authApi.renewPassword({
        login,
        previousPassword: null,
        tid,
        token,
      })
    },
    [dispatch]
  )

  const attest = useCallback(
    async (
      email: string,
      renewPassword: string,
      tid?: string,
      token?: string
    ): Promise<void> => {
      const login: LoginParameter = {
        email: email,
        password: renewPassword,
      }
      await authApi.attest({
        login,
        previousPassword: null,
        tid,
        token,
      })
    },
    [dispatch]
  )

  const signOut = useCallback(async (): Promise<void> => {
    localStorage.removeItem(STORAGE_KEY)
    dispatch({ type: ActionType.SIGN_OUT })
  }, [dispatch])

  const lostAccess = useCallback(
    async (email: string): Promise<void> => {
      await authApi.lostAccess({ email })
    },
    [dispatch]
  )

  const updateUserContext = useCallback(
    async (user: Partial<User>): Promise<void> => {
      dispatch({
        type: ActionType.UPDATE,
        payload: {
          user,
        },
      })
    },
    [dispatch]
  )

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        signIn,
        signUp,
        signOut,
        renewPassword,
        attest,
        lostAccess,
        updateUserContext,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const AuthConsumer = AuthContext.Consumer
