import type { Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { usePageView } from '@/hooks/use-page-view'
import { paths } from '@/paths'
import type { Page as PageType } from '@/types/page'
import useTranslate from '@/hooks/use-translate'

export const Error401: PageType = () => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { t: tCom } = useTranslate('common')

  usePageView()

  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          py: '80px',
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 6,
            }}
          >
            <Box
              alt="Not authorized"
              component="img"
              src="/assets/errors/error-401.png"
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 400,
              }}
            />
          </Box>
          <Typography align="center" variant={mdUp ? 'h1' : 'h4'}>
            {tCom('message401')}
          </Typography>
          <Typography align="center" color="text.secondary" sx={{ mt: 0.5 }}>
            {tCom('messageErrorPage')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6,
            }}
          >
            {/*there was RouterLink here before but it wasn't reloading the page...*/}
            <Button onClick={() => window.location.reload()} href={paths.index}>
              {tCom('backToHome')}
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default Error401
