import { type FC, type ReactNode } from 'react'
import { withAuthGuard } from '@/hocs/with-auth-guard'
import { useSettings } from '@/hooks/use-settings'
import { useSections } from './config'
import { VerticalLayout } from './vertical-layout'

interface LayoutProps {
  children?: ReactNode
  isFullscreen?: boolean
}

export const Layout: FC<LayoutProps> = withAuthGuard((props) => {
  const settings = useSettings()
  const sections = useSections()

  return (
    <VerticalLayout
      sections={sections}
      navColor={settings.navColor}
      isFullscreen={props.isFullscreen}
      {...props}
    />
  )
}, null)
