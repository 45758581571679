import useTranslate from '@/hooks/use-translate'
import { nabuColors } from '@/theme/colors'
import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'

import { Plus } from '@untitled-ui/icons-react'

import { FC, useCallback, useState } from 'react'
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone'

export interface DropzoneComponentProps {
  /**
   * Whether the dropzone is active
   */
  isDragActive: boolean
  /**
   * Root props of thz dropzone
   */
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
  /**
   * Input props of the dropzone
   */
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T
  /**
   * Text to display in the email
   */
  emailText?: string
  /**
   * On drop
   */
  onDrop?: () => void
}

export const DropzoneComponent: FC<DropzoneComponentProps> = (props) => {
  const {
    isDragActive,
    getRootProps,
    getInputProps,
    emailText = 'example@example.com',
    onDrop,
  } = props
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)

  const { t: tCommon } = useTranslate('common')
  const { t: tUpload } = useTranslate('upload')
  const { t: tDashboard } = useTranslate('dashboard')

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard
      .writeText(emailText)
      .then(() => {
        setSnackbarOpen(true)
      })
      .catch((error) => {
        console.error('Error copying text to clipboard:', error)
      })
  }, [emailText])

  return (
    <Box
      sx={{
        alignItems: 'center',
        border: 2,
        borderRadius: 1.2,
        borderStyle: 'dashed',
        backgroundColor: 'transparent',
        borderColor: `${nabuColors.greenCyan}`,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        outline: 'none',
        width: '100%',
        // p: 4,
        ...(isDragActive && {
          backgroundColor: 'action.active',
          opacity: 0.5,
        }),
        '&:hover': {
          backgroundColor: 'action.hover',
          cursor: 'pointer',
          opacity: 0.8,
        },
      }}
      {...getRootProps()}
      data-testid="dropzone"
    >
      <input
        {...getInputProps()}
        data-testid="dropzone-input"
        multiple={false}
        accept=".pdf, .jpeg, .jpg, .png, .xls, .xlsx, .ods"
      />
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{ margin: '32px' }}
      >
        <Avatar
          sx={{
            height: 60,
            width: 60,
            color: `${nabuColors.whiteColor}`,
            backgroundColor: `${nabuColors.greenCyan}`,
          }}
        >
          <SvgIcon fontSize="large">
            <Plus />
          </SvgIcon>
        </Avatar>
        <Stack
          spacing={1}
          sx={{ width: 'min-content', minWidth: '220px', textAlign: 'left' }}
        >
          <Typography variant="h6" data-testid="drop-files-here">
            {tUpload('dropText')}
          </Typography>
          {/*<Typography
            variant="caption"
            color="grey"
            onMouseUp={(e) => e.stopPropagation()}
          >
            <SelectableText onClick={(e) => e.stopPropagation()}>
              {tUpload('emailHookText')} {emailText}
            </SelectableText>
            <Tooltip title={tCommon('copy')} placement="top">
              <IconButton
                type="button"
                onClick={(e) => {
                  handleCopyToClipboard()
                  e.stopPropagation()
                }}
                data-testid="copy-button"
              >
                <Copy01 data-testid="copy-icon" />
              </IconButton>
            </Tooltip>
              </Typography>*/}
        </Stack>
      </Stack>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setSnackbarOpen(false)}
        sx={{ zIndex: 9999 }}
      >
        <Alert
          severity="success"
          onClose={() => setSnackbarOpen(false)}
          data-testid="copy-confirm-alert"
        >
          {tDashboard('theEmailWasCopiedToYourClipboard')}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default DropzoneComponent
