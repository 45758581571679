import { FC, ChangeEvent, useEffect } from 'react'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { nabuColors } from '@/theme/colors'
import DialogUploadValuesv2 from '@/types/dialogUploadValuesv2'
import useTranslate from '@/hooks/use-translate'

interface FastTrackCheckboxProps {
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  values: DialogUploadValuesv2
}

const FastTrackCheckbox: FC<FastTrackCheckboxProps> = ({
  checked,
  onChange,
  values,
}) => {
  const isDisabled = values.files.length > 1
  const { t: tDashboard } = useTranslate('dashboard')

  // Effect to reset the checkbox when values.files changes from empty to having more than one item
  useEffect(() => {
    if (checked && values.files.length > 1) {
      // Trigger onChange to set the checked state to false
      onChange({ target: { checked: false } } as ChangeEvent<HTMLInputElement>)
    }
  }, [values.files.length, checked, onChange])

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          color="primary"
          disabled={isDisabled}
        />
      }
      label={
        <span style={{ fontSize: '12px' }}>
          <Typography
            component="span"
            sx={{
              color: isDisabled
                ? nabuColors.greyColorLight3
                : nabuColors.blackColor3,
              fontSize: '12px',
              marginRight: '4px',
            }}
          >
            {tDashboard('fastTrackFirstText')}
          </Typography>
          <Typography
            component="span"
            sx={{
              color: nabuColors.greyColorLight3,
              fontSize: '12px',
            }}
          >
            {tDashboard('fastTrackSecondText')}
          </Typography>
        </span>
      }
      sx={{
        '& .MuiFormControlLabel-label': {
          fontSize: '12px',
        },
      }}
      disabled={isDisabled}
    />
  )
}

export default FastTrackCheckbox
