import { Configuration, Flux, retrieveFluxById } from '@/api'
import useTranslate from '@/hooks/use-translate'
import getFieldTestId from '@/utils/getFieldTestId'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useFormikContext } from 'formik'
import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import ExportTypeChip from '@/components/ExportTypeChip'
import { determineDeclarationType } from '@/utils/determineDeclarationType'
import ClearIcon from '@mui/icons-material/Clear'
import DialogUploadValuesv2 from '@/types/dialogUploadValuesv2'
import { nabuColors, neutral } from '@/theme/colors'

export interface ConfigurationsSectionInputProps {
  /**
   * Configurations
   */
  configurations: Configuration[]
  /**
   * Name of the field
   */
  name: keyof DialogUploadValuesv2
  setCurrentSelectedConfigurationId: (value: string) => void
  setFilterOptionsWorkflow: (optionsWorkflow: Flux[]) => void
  optionsWorkflow: Flux[]
  getWorkFlowOfGroup: () => void
  checkedCheckbox: boolean
  setSelectTypeDeclarationValue: Dispatch<SetStateAction<string>>
}

/**
 * ConfigurationsSectionInput is a form input component that allows users to select an export configuration.
 * The selected configuration impacts the available workflows, and the component provides feedback based on user interaction.
 *
 * @param {ConfigurationsSectionInputProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered ConfigurationsSectionInput component.
 */
const ConfigurationsSectionInput: FC<ConfigurationsSectionInputProps> = ({
  configurations,
  name,
  setCurrentSelectedConfigurationId,
  setFilterOptionsWorkflow,
  optionsWorkflow,
  getWorkFlowOfGroup,
  checkedCheckbox,
  setSelectTypeDeclarationValue,
}) => {
  const { t: tCommon } = useTranslate('common')
  const formik = useFormikContext<DialogUploadValuesv2>()

  // Effect to retrieve and set the configuration based on the selected workflow.
  useEffect(() => {
    if (formik.values.workflow) {
      retrieveFluxById(formik.values.workflow)
        .then((currentWorkflow: Flux) => {
          const selectedConfiguration = configurations?.find(
            (c) =>
              c.name &&
              c.name
                .toLowerCase()
                .includes(currentWorkflow.declarationType as string)
          )

          if (selectedConfiguration) {
            formik.setFieldValue(name, selectedConfiguration.cid)
            selectedConfiguration.cid &&
              setCurrentSelectedConfigurationId(selectedConfiguration.cid)
          }
        })
        .catch((e) => console.error('error retrieveFluxById', e))
    }
  }, [formik.values.workflow])

  /**
   * Resets the input value to an empty string if the group changes
   */
  // NOTE - added don't reset value unless the value has also changed...
  useEffect(() => {
    if (
      formik.values.group &&
      formik.initialValues[name] !== formik.values[name]
    ) {
      formik.setFieldValue(name, '') // Resets the input value to an empty string if the group changes
    }
  }, [formik.values.group, name])

  /**
   * Handles the change event for the configuration selection.
   * This updates the selected configuration and resets the workflow field.
   *
   * @param {SelectChangeEvent<string>} event - The change event from the select input.
   */
  const handleConfigurationChange = (event: SelectChangeEvent<string>) => {
    const selectedConfigurationId = event.target.value

    // Update the selected value using the setSelectTypeDeclarationValue function
    setSelectTypeDeclarationValue(selectedConfigurationId)

    setCurrentSelectedConfigurationId(selectedConfigurationId)
    formik.setFieldValue(name, selectedConfigurationId)
    formik.setFieldValue('workflow', '')
  }

  /**
   * Clears the selected configuration and resets related fields.
   */
  const handleClearInput = () => {
    formik.setFieldValue(name, '')
    formik.setFieldValue('workflow', '')

    // Update the selected value using the setSelectTypeDeclarationValue function
    setSelectTypeDeclarationValue('')

    setCurrentSelectedConfigurationId('')
    setFilterOptionsWorkflow(optionsWorkflow)
    getWorkFlowOfGroup()
  }

  return (
    <>
      <Select
        label="Service d'export"
        labelId="export-service-select"
        id="export-service-select"
        sx={{
          marginBottom: 1,
          height: '56px',
          // When the checkbox Fast track is checked, the border color of select change
          borderColor: 'red',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: checkedCheckbox
              ? `${nabuColors.greenCyan}`
              : `${neutral[200]}`,
            border: checkedCheckbox
              ? `2px solid ${nabuColors.greenCyan}`
              : `1px solid ${neutral[200]}`,
          },
        }}
        error={
          (formik.touched.exportConfiguration &&
            Boolean(formik.errors.exportConfiguration)) ||
          (!formik.values.exportConfiguration &&
            Boolean(formik.errors.exportConfiguration))
        }
        {...formik.getFieldProps(name)}
        {...{ ['data-testid']: 'export-configuration-input' }}
        onChange={handleConfigurationChange}
        endAdornment={
          formik.values.exportConfiguration && (
            <InputAdornment position="end" sx={{ marginX: 2 }}>
              <IconButton
                onClick={() => handleClearInput()}
                data-testid="string-field-icon-button"
              >
                <ClearIcon data-testid="export-configuration-clear-icon" />
              </IconButton>
            </InputAdornment>
          )
        }
      >
        {configurations?.length > 0 ? (
          configurations.map((option, index) => (
            <MenuItem
              key={option.name}
              value={option.cid}
              {...getFieldTestId(`${name}-${index}`)}
            >
              <ExportTypeChip
                exportType={determineDeclarationType(option.name as string)}
              />
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>{tCommon('noOptionAvailable')}</MenuItem>
        )}
      </Select>

      <Typography variant="caption" color={'error'}>
        {(formik.touched.exportConfiguration &&
          Boolean(formik.errors.exportConfiguration) &&
          formik.errors.exportConfiguration) ||
          (!formik.values.exportConfiguration &&
            Boolean(formik.errors.exportConfiguration) &&
            formik.errors.exportConfiguration)}
      </Typography>
    </>
  )
}

export default ConfigurationsSectionInput
