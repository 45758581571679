import { Document, Page, pdfjs } from 'react-pdf'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import SvgIcon from '@mui/material/SvgIcon'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'

import { Trash01 } from '@untitled-ui/icons-react'
import ImageWithChevron from '../ImageWithChevron'
import Excel from '@/icons/untitled-ui/duocolor/excel'
import { nabuColors } from '@/theme/colors'

// Set up the worker for PDF rendering
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface FileThumbnailProps {
  /**
   * Page number to render
   */
  pageNumber: number
  /**
   * Width of the thumbnail
   */
  width?: number
  /**
   * File to render
   */
  file?: File
  /**
   * Whether to show the title
   */
  showTitle?: boolean
  /**
   * URL of the file
   */
  url?: string | null
  /**
   * Whether the thumbnail is loading (not used)
   */
  _isLoading?: boolean
  /**
   * Callback when the remove button is clicked
   * @param {File} file The file to remove
   */
  onRemove?: (file?: File) => void
  /**
   * to manage local url during loading step
   */
  flagLocal?: boolean
}

/**
 * The FileThumbnail component renders a thumbnail for a file (PDF or image).
 * It supports displaying a page from a PDF file or an image, and includes an option to remove the file.
 *
 * @param {FileThumbnailProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered FileThumbnail component.
 */
export const FileThumbnail: FC<FileThumbnailProps> = (props) => {
  const {
    pageNumber,
    width,
    file,
    onRemove,
    url,
    _isLoading,
    showTitle = true,
    flagLocal = false,
  } = props
  // State to manage the loading status of the thumbnail.
  const [isLoading, setIsLoading] = useState(_isLoading)

  // Effect to update the loading status when the _isLoading prop changes.
  useEffect(() => {
    setIsLoading(_isLoading)
  }, [_isLoading])

  // Generate a temporary URL for local files, if necessary.
  const url_temp =
    file && URL && URL.createObjectURL ? URL.createObjectURL(file as File) : ''

  return (
    <Card
      elevation={1}
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? 'neutral.800' : 'background.paper',
        px: 4,
        py: 2,
        borderRadius: 1.5,
        userSelect: 'none',
        '&:hover': {
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? 'neutral.700' : 'neutral.50',
        },
        '&.MuiPaper-elevation1': {
          boxShadow: 0,
        },
        width: width,
      }}
    >
      <Box
        sx={{
          mb: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {file && url?.endsWith('.pdf') ? (
          <Document file={file} onLoadSuccess={() => setIsLoading(false)}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Page
                pageNumber={pageNumber}
                width={width}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                renderMode="canvas"
              />
            )}
          </Document>
        ) : (file &&
            (file.type.includes('spreadsheetml') ||
              file.type.includes('ms-excel'))) ||
          url?.endsWith('.json') ? (
          <Excel
            width={file ? 64 : 128}
            height={file ? 64 : 128}
            fill={nabuColors.greenCyan}
          />
        ) : isLoading ? (
          <CircularProgress />
        ) : (
          <ImageWithChevron
            url={url && !flagLocal ? url : url_temp}
            flagLocal={flagLocal}
          />
        )}
      </Box>
      {file && showTitle && (
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={8}>
            <Typography
              variant="caption"
              sx={{
                height: '40px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {file!.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <IconButton
              edge="end"
              onClick={(e) => {
                e.stopPropagation()
                onRemove?.(file)
              }}
              data-testid="file-input-remove-button"
            >
              <SvgIcon>
                <Trash01 />
              </SvgIcon>
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Card>
  )
}

export default FileThumbnail
