import { useEffect, type FC } from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import { usePopover } from '@/hooks/use-popover'
import { AccountPopover } from './account-popover'
import { getInitials } from '@/utils/getInitials'
import { getColor } from '@/utils/getColor'
import { useAuth } from '@/hooks/use-auth'
import { PartialUser, generateZendeskToken } from '@/api'
import { ZendeskAPI } from '@/utils/zendeskConfig'

export const AccountButton: FC = () => {
  const { user } = useAuth()

  useEffect(() => {
    // sign the user into zendesk
    const fetchZendeskToken = async () => {
      try {
        const response = await generateZendeskToken()
        const zendeskToken = response.zendeskToken

        if (zendeskToken) {
          ZendeskAPI(
            'messenger',
            'loginUser',
            (callback: (s: string) => void) => {
              try {
                callback(zendeskToken)
              } catch (error) {
                console.warn(
                  `Failed to connect to Zendesk : ${JSON.stringify(error)}`
                )
              }
            }
          )
        } else {
          console.warn('Zendesk token not found in response')
        }
      } catch (error) {
        console.warn(
          `Failed to generate Zendesk token: ${JSON.stringify(error)}`
        )
      }
    }
    if (user) {
      void fetchZendeskToken()
    }
  }, [user])

  const popover = usePopover<HTMLButtonElement>()

  return (
    <>
      {user !== null && (
        <Box
          component={ButtonBase}
          onClick={popover.handleOpen}
          ref={popover.anchorRef}
          data-testid="account-button"
          sx={{
            alignItems: 'center',
            display: 'flex',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'divider',
            height: 40,
            width: 40,
            borderRadius: '50%',
          }}
        >
          <Avatar
            sx={{
              color: 'white',
              bgcolor:
                user.profile?.firstName && user.profile?.lastName
                  ? getColor(
                      user.profile?.firstName + ' ' + user.profile?.lastName
                    )
                  : 'grey',
            }}
          >
            {getInitials(
              user.profile?.firstName + ' ' + user.profile?.lastName
            )}
          </Avatar>
        </Box>
      )}
      {user !== null && (
        <AccountPopover
          user={user as PartialUser}
          anchorEl={popover.anchorRef.current}
          onClose={popover.handleClose}
          open={popover.open}
        />
      )}
    </>
  )
}
