import type { User } from '@/types/user'
import { createResourceId } from './create-resource-id'
import { apiConfig } from '@/config'
import { wait } from '@/utils/wait'
import { users } from './data'
import {
  callAccessRecovery,
  callAccountAttest,
  callAccountLogin,
  callAccountLostAccess,
  callAccountMe,
  RecoveryParameter,
  UserProfile,
} from '../index'

const STORAGE_KEY = 'users'

// NOTE: We use localStorage since memory storage is lost after page reload.
//  This should be replaced with a server call that returns DB persisted data.

const getPersistedUsers = (): User[] => {
  try {
    const data = localStorage.getItem(STORAGE_KEY)

    if (!data) {
      return []
    }

    return JSON.parse(data) as User[]
  } catch (err) {
    console.error(err)
    return []
  }
}

const persistUser = (user: User): void => {
  try {
    const users = getPersistedUsers()
    const data = JSON.stringify([...users, user])
    localStorage.setItem(STORAGE_KEY, data)
  } catch (err) {
    console.error(err)
  }
}

type SignInRequest = {
  email: string
  password: string
}

type SignInResponse = Promise<{
  accessToken: string
}>

type SignUpRequest = {
  email: string
  firstName: string
  lastName: string
  password: string
}

type LostAccessRequest = {
  email: string
}

type RenewPasswordRequest = {
  renewPassword: string
  tid: string | null
  token: string | null
}

type SignUpResponse = Promise<{
  accessToken: string
}>

type MeRequest = {
  accessToken: string
}

type MeResponse = Promise<UserProfile>

class AuthApi {
  async signIn(request: SignInRequest): SignInResponse {
    return new Promise(async (resolve, reject) => {
      try {
        const signInResponse = await callAccountLogin(request, {
          baseUrl: `${apiConfig().apiUrl.replace(/\/api$/, '')}`,
        })
        const headers = signInResponse.headers
        const authorizationHeader = headers.get('Authorization') as string
        const accessToken = authorizationHeader.split(' ')[1]

        resolve({ accessToken })
      } catch (err) {
        console.log(err)
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }

  async lostAccess(request: LostAccessRequest) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await callAccountLostAccess(request, {
          baseUrl: `${apiConfig().apiUrl.replace(/\/api$/, '')}`,
        })
        resolve({ response })
      } catch (err) {
        console.log(err)
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }

  async renewPassword(request: RecoveryParameter) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await callAccessRecovery(request, {
          baseUrl: `${apiConfig().apiUrl.replace(/\/api$/, '')}`,
        })
        resolve({ response })
      } catch (err) {
        console.log(err)
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }

  async attest(request: RecoveryParameter) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await callAccountAttest(request, {
          baseUrl: `${apiConfig().apiUrl.replace(/\/api$/, '')}`,
        })
        resolve({ response })
      } catch (err) {
        console.log(err)
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }

  async signUp(request: SignUpRequest): SignUpResponse {
    const { email, firstName, lastName, password } = request

    await wait(1000)

    return new Promise((resolve, reject) => {
      try {
        // Merge static users (data file) with persisted users (browser storage)
        const mergedUsers = [...users, ...getPersistedUsers()]

        // Check if a user already exists
        let user = mergedUsers.find((user) => user.email === email)

        if (user) {
          reject(new Error('User already exists'))
          return
        }

        user = {
          id: createResourceId(),
          email,
          firstName,
          lastName,
          password,
        }

        persistUser(user)

        /** 
        const accessToken = sign({ userId: user.id }, JWT_SECRET, {
          expiresIn: JWT_EXPIRES_IN,
        })
        */
        resolve({ accessToken: 'accessToken' })
      } catch (err) {
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }

  me(request: MeRequest): MeResponse {
    const { accessToken } = request

    return new Promise(async (resolve, reject) => {
      try {
        const user = await callAccountMe({
          headers: {
            Authorization: accessToken,
          },
        })

        resolve(user)
      } catch (err) {
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }
}

export const authApi = new AuthApi()
