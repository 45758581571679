import type { FC } from 'react'
import { useState, useMemo, useCallback, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Plus } from '@untitled-ui/icons-react'
import Upload from '@mui/icons-material/Upload'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { Logo } from '@/components/Logo'
import { RouterLink } from '@/components/RouterLink'
import { usePathname } from '@/hooks/use-pathname'
import { paths } from '@/paths'
import type { NavColor } from '@/types/settings'
import type { Section } from '../config'
import { SideNavSection } from './side-nav-section'
import DialogUploadv2 from '@/components/DialogUploadv2'
import { callStatisticsUserGlobal, findBatchImport } from '@/api'
import { useMounted } from '@/hooks/use-mounted'
import useTranslate from '@/hooks/use-translate'
import { nabuColors } from '@/theme/colors'

const SIDE_NAV_WIDTH = 250
const SIDE_NAV_FOLDED_WIDTH = 64

const useCssVars = (color: NavColor): Record<string, string> => {
  const theme = useTheme()

  return useMemo((): Record<string, string> => {
    switch (color) {
      case 'blend-in':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.neutral[100],
            '--nav-border-color': theme.palette.neutral[700],
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[600],
            '--nav-item-icon-color': theme.palette.neutral[500],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
            '--nav-item-chevron-color': theme.palette.neutral[700],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          }
        } else {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.text.primary,
            '--nav-border-color': theme.palette.neutral[100],
            '--nav-logo-border': theme.palette.neutral[100],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.text.secondary,
            '--nav-item-hover-bg': theme.palette.action.hover,
            '--nav-item-active-bg': theme.palette.action.selected,
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[400],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[400],
            '--nav-item-chevron-color': theme.palette.neutral[400],
            '--nav-scrollbar-color': theme.palette.neutral[900],
          }
        }

      case 'discreet':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.neutral[900],
            '--nav-color': theme.palette.neutral[100],
            '--nav-border-color': theme.palette.neutral[700],
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[600],
            '--nav-item-icon-color': theme.palette.neutral[500],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
            '--nav-item-chevron-color': theme.palette.neutral[700],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          }
        } else {
          return {
            '--nav-bg': theme.palette.neutral[50],
            '--nav-color': theme.palette.text.primary,
            '--nav-border-color': theme.palette.divider,
            '--nav-logo-border': theme.palette.neutral[200],
            '--nav-section-title-color': theme.palette.neutral[500],
            '--nav-item-color': theme.palette.neutral[500],
            '--nav-item-hover-bg': theme.palette.action.hover,
            '--nav-item-active-bg': theme.palette.action.selected,
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[400],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[400],
            '--nav-item-chevron-color': theme.palette.neutral[400],
            '--nav-scrollbar-color': theme.palette.neutral[900],
          }
        }

      case 'evident':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.neutral[800],
            '--nav-color': theme.palette.common.white,
            '--nav-border-color': 'transparent',
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          }
        } else {
          return {
            '--nav-bg': theme.palette.neutral[800],
            '--nav-color': theme.palette.common.white,
            '--nav-border-color': 'transparent',
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          }
        }

      default:
        return {}
    }
  }, [theme, color])
}

function updateBadgeLabel(
  sections: Section[],
  title: string,
  newValue: number | undefined
): Section[] {
  return sections.map((section: Section) => {
    if (section.items && Array.isArray(section.items)) {
      let updatedItems = section.items.map((item: any) => {
        if (item.title === title) {
          if (newValue) return { ...item, badgeLabel: newValue }
        }
        // Gère également les items imbriqués
        if (item.items && Array.isArray(item.items)) {
          let updatedSubItems = item.items.map((subItem: any) => {
            if (subItem.title === title) {
              return { ...subItem, badgeLabel: newValue }
            }
            return subItem
          })
          return { ...item, items: updatedSubItems }
        }
        return item
      })
      return { ...section, items: updatedItems }
    }
    return section
  })
}

interface SideNavProps {
  color?: NavColor
  sections?: Section[]
  isFolded?: boolean
}

export const SideNav: FC<SideNavProps> = (props) => {
  const { color = 'evident', sections = [], isFolded = false } = props
  const [customSections, setCustomSections] = useState<Section[]>([])
  const isMounted = useMounted()
  const { t: tNav } = useTranslate('nav')
  const { t: tDash } = useTranslate('dashboard')
  const [openDialog, setOpenModal] = useState(false)

  const pathname = usePathname()
  const cssVars = useCssVars(color)

  const handleClickOpen = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const { refetch } = useQuery({
    queryKey: ['activities'],
    queryFn: () =>
      findBatchImport({
        page: 0,
        size: 5,
        orderBy: ['createdAt'],
        reverse: ['true'],
      }),
  })

  const {
    isLoading: isStatsLoading,
    isSuccess: isStatsSuccess,
    error: statsError,
    data: statsData,
  } = useQuery({
    queryKey: ['stats'],
    queryFn: () => callStatisticsUserGlobal(),
  })

  useEffect(() => {
    setCustomSections(
      updateBadgeLabel(
        sections,
        tNav('fieldToManage'),
        statsData?.customsFilesToProcess
      )
    )
  }, [sections])

  // pour mettre à jour la cache des batch import lorsque l'on passe par l'upload de la nav bar
  const handleUploadRecord = useCallback(async () => {
    try {
      if (isMounted()) {
        refetch()
      }
    } catch (err) {
      console.error(err)
    }
  }, [isMounted, refetch])

  return (
    <Drawer
      anchor="left"
      open
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: 'var(--nav-bg)',
          borderRightColor: 'var(--nav-border-color)',
          borderRightStyle: 'solid',
          borderRightWidth: 1,
          color: 'var(--nav-color)',
          width: isFolded ? SIDE_NAV_FOLDED_WIDTH : SIDE_NAV_WIDTH,
        },
      }}
      variant="permanent"
    >
      <Stack sx={{ height: '100%' }}>
        <Stack
          alignItems="center"
          direction="row"
          sx={{ p: isFolded ? 2 : 2, justifyContent: 'left', marginBottom: 2 }}
        >
          <Box
            component={RouterLink}
            href={paths.index}
            sx={{
              display: 'flex',
              height: 34,
              width: 40,
              p: '4px',
            }}
          >
            <Logo width={isFolded ? 32 : 48} height={isFolded ? 32 : 48} />
          </Box>
        </Stack>
        <Stack
          alignItems="left"
          direction="row"
          spacing={2}
          sx={{ p: isFolded ? 1 : 2, mb: 2, justifyContent: 'center' }}
        >
          {isFolded ? (
            <IconButton
              sx={{
                backgroundColor: `${nabuColors.greenCyan}`,
                color: `${nabuColors.whiteColor}`,
                '&:hover': { backgroundColor: `${nabuColors.greenPine}` },
              }}
              onClick={handleClickOpen}
              data-testid="button-new-folder-in-sidebar"
              title={tDash('newFolderButton')}
            >
              <Upload />
            </IconButton>
          ) : (
            <Button
              startIcon={<Plus />}
              sx={{
                width: '100%',
                minWidth: 'unset',
              }}
              variant="contained"
              onClick={handleClickOpen}
              data-testid="button-new-folder-in-sidebar"
              title={tDash('newFolderButton')}
            >
              {tDash('newFolderButton')}
            </Button>
          )}
          <DialogUploadv2
            open={openDialog}
            onClose={handleClose}
            handleUploadRecord={() => handleUploadRecord()}
            data-testid="dialog-upload-new-folder"
          />
        </Stack>
        <Stack
          component="nav"
          spacing={2}
          sx={{
            flexGrow: 1,
            px: isFolded ? 1 : 2,
          }}
        >
          {customSections.map((section, index) => (
            <SideNavSection
              items={section.items}
              key={index}
              pathname={pathname}
              subheader={section.subheader}
              isFolded={isFolded}
            />
          ))}
        </Stack>
      </Stack>
    </Drawer>
  )
}
