import { type FC } from 'react'
import { useAuth } from '@/hooks/use-auth'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import useTranslate from '@/hooks/use-translate'
import { nabuColors } from '@/theme/colors'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next'
import { defaultConfig } from '@/config'

/**
 * HelpNabuButton component - displays an icon button that opens the Nabu help center
 * in a new tab based on the user's language preference.
 */
export const HelpNabuButton: FC = () => {
  // Retrieve the authenticated user from the custom hook
  const { user } = useAuth()

  // Custom hook for translations
  const { t: tCommon } = useTranslate('common')

  // Retrieve the current language from the translation hook
  const { i18n } = useTranslation()

  // Determine the language code ('fr' or 'en')
  const lang = i18n?.language
    ? (i18n.language.toLowerCase() as 'en' | 'fr')
    : 'fr'

  /**
   * Handle the button click to open the help center URL in a new tab.
   */
  const handleNabuButtonOpen = () => {
    const url =
      lang === 'fr'
        ? `${defaultConfig().helpNabu}/fr`
        : `${defaultConfig().helpNabu}/en-150`
    window.open(url, '_blank')
  }

  return (
    <>
      {user !== null && (
        <IconButton
          onClick={handleNabuButtonOpen}
          data-testid="help-nabu-button"
          color="default"
          title={tCommon('help')}
          sx={{
            color: `${nabuColors.whiteColor}`,
            backgroundColor: `${nabuColors.greyColorDark}`,
            height: 40,
            width: 40,
            borderRadius: '50%',
            '&:hover, &.Mui-focusVisible': {
              background: `${nabuColors.greyColorDarkest2}`,
            },
          }}
        >
          <QuestionMarkIcon />
        </IconButton>
      )}
    </>
  )
}
