import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import type { DropzoneOptions, FileWithPath } from 'react-dropzone'
import { useQuery } from '@tanstack/react-query'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { Form, Formik, FormikHelpers } from 'formik'
import { useMounted } from '@/hooks/use-mounted'
import useTranslate from '@/hooks/use-translate'
import {
  callAccountGroups,
  callAccountOrganization,
  Configuration,
  findConfiguration,
  Group,
  Organization,
} from '@/api'
import { GroupSectionInput } from './SectionInputs/GroupSectionInput'
import { FolderNameSectionInput } from './SectionInputs/FolderNameSectionInput'
import { FilesDropInput } from './SectionInputs/FilesDropInput'
import { buildValidationSchema, handleSubmit } from './utils'
import DialogUploadValuesv2 from '@/types/dialogUploadValuesv2'
import FormControlConfigurationsWorkflow from './SectionInputs/FormControlConfigurationsWorkflow'

import Tooltip from '@mui/material/Tooltip'
import FastTrackCheckbox from './FastTrackCheckBox'

export interface DialogUploadProps extends DropzoneOptions {
  /**
   * Whether the dialog is open
   */
  open: boolean
  /**
   * Input files to display
   */
  inputFiles?: FileWithPath[]
  /**
   * On close
   */
  onClose: () => void
  /**
   * On upload record
   */
  handleUploadRecord?: () => void

  defaultGroupId?: string // for testing
  defaultFluxId?: string // for testing
}

/**
 * DialogUploadv2 component
 *
 * A dialog component that handles file uploads, organizes them into folders,
 * and submits the data to a server. Uses Formik for form handling and MUI for
 * UI components.
 *
 * @param {DialogUploadProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered component.
 */
export const DialogUploadv2: FC<DialogUploadProps> = (props) => {
  const {
    open,
    inputFiles = [],
    handleUploadRecord = () => undefined,
    onClose,
    defaultGroupId,
    defaultFluxId,
  } = props

  // Initial form values
  const initialValues: DialogUploadValuesv2 = {
    files: inputFiles.length > 0 ? inputFiles : [],
    recordName: inputFiles.length > 0 ? inputFiles[0].name : '',
    exportConfiguration: '',
    group: defaultGroupId || '',
    workflow: defaultFluxId || '',
  }

  const { t: tCommon } = useTranslate('common')
  const { t: tDashboard } = useTranslate('dashboard')

  const validationSchema = buildValidationSchema()

  const isMounted = useMounted()

  const [currentOrganization, setCurrentOrganization] = useState<Organization>()
  const [configurations, setConfigurations] = useState<Configuration[]>()
  const [myGroups, setMyGroups] = useState<Group[]>()
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false)

  // State for the checkbox
  const [checkedCheckboxFastTrack, setCheckedCheckboxFastTrack] =
    useState(false)
  // State Select Type Declaration
  const [selectTypeDeclarationValue, setSelectTypeDeclarationValue] =
    useState('')

  // Load the configurations
  const { data: { data: configurationsData } = {} } = useQuery({
    queryKey: ['findConfigurations'],
    queryFn: () => findConfiguration(),
  })

  useEffect(() => {
    setConfigurations(configurationsData)
  }, [configurationsData])

  const onSubmit = useCallback(
    (
      values: DialogUploadValuesv2,
      helpers: FormikHelpers<DialogUploadValuesv2>
    ) => {
      if (!submitInProgress) {
        setSubmitInProgress(true)
        handleSubmit({
          values,
          helpers,
          handleUploadRecord,
          onClose,
          successMessage: tCommon('sentDocuments'),
          isMounted,
          setSubmitInProgress,
          checkedCheckboxFastTrack,
        })
      }
    },
    [handleUploadRecord, onClose, isMounted, tCommon]
  )

  /**
   * Handle checkbox change event.
   *
   * @param {ChangeEvent<HTMLInputElement>} event - The event triggered by the checkbox.
   */
  const handleClose = useCallback(
    (handleReset: () => void) => {
      handleReset()
      onClose()
    },
    [onClose]
  )

  useEffect(() => {
    // On mount
    if (open) {
      callAccountOrganization()
        .then((organization) => {
          setCurrentOrganization(organization)
        })
        .catch((e) => console.log('error callAccountOrganization', e))
      callAccountGroups()
        .then((validGroups) => {
          setMyGroups(validGroups.data)
        })
        .catch((e) => console.log('error callAccountGroups', e))
    } else {
      // Reset checkedCheckboxFastTrack to false when modal is closed
      setCheckedCheckboxFastTrack(false)
    }
  }, [open])

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedCheckboxFastTrack(event.target.checked)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {({ isValid, handleReset, values }) => (
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={open}
          onClose={() => handleClose(handleReset)}
        >
          <Form data-testid="dialog-upload-form">
            <DialogTitle>
              <Stack direction="row" alignItems="center" spacing={2}>
                <GroupSectionInput name="group" myGroups={myGroups} />
                <ChevronRight />
                <Typography variant="h6">
                  {tCommon('creationOfNewFolder')}
                </Typography>
              </Stack>
            </DialogTitle>

            <DialogContent>
              <DialogContentText component="div">
                <FilesDropInput
                  name="files"
                  data-testid="files-drop-input"
                  values={values}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    my: 4,
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} md={4}>
                      <Typography variant="h6">
                        {tDashboard('folderInformation')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Stack spacing={3}>
                        <FolderNameSectionInput name="recordName" />
                        <FormControlConfigurationsWorkflow
                          configurations={
                            configurations?.filter(
                              (el) => el.groupId === values.group
                            ) || []
                          }
                          groupId={values.group}
                          name="workflow"
                          values={values}
                          checkedCheckbox={checkedCheckboxFastTrack}
                          setSelectTypeDeclarationValue={
                            setSelectTypeDeclarationValue
                          }
                        />
                        <FastTrackCheckbox
                          checked={checkedCheckboxFastTrack}
                          onChange={handleCheckboxChange}
                          values={values}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                sx={{ mt: 2 }}
              >
                <Button
                  color="inherit"
                  size="small"
                  type="button"
                  onClick={() => handleClose(handleReset)}
                  data-testid="cancel-button"
                >
                  {tCommon('cancel')}
                </Button>
                {/* Tooltip only display when fast track checkbox is checked and files at least only one file is upload */}
                <Tooltip
                  title={
                    checkedCheckboxFastTrack &&
                    values.files.length > 0 &&
                    selectTypeDeclarationValue === ''
                      ? `${tDashboard('fastTrackTooltipText')}`
                      : ''
                  }
                >
                  <span>
                    <Button
                      disabled={
                        !isValid ||
                        submitInProgress ||
                        (checkedCheckboxFastTrack &&
                          values.files.length > 0 &&
                          selectTypeDeclarationValue === '')
                      }
                      size="small"
                      type="submit"
                      variant="contained"
                      data-testid="submit-button"
                    >
                      {tCommon('validate')}
                    </Button>
                  </span>
                </Tooltip>
              </Stack>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  )
}

export default DialogUploadv2
