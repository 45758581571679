import type { PaletteColor } from '@mui/material/styles/createPalette'
import type { ColorPreset } from './index'
import { blue, green, indigo, purple, cyan } from './colors'

export const getPrimary = (preset?: ColorPreset): PaletteColor => {
  switch (preset) {
    case 'blue':
      return blue
    case 'green':
      return green
    case 'indigo':
      return indigo
    case 'cyan':
      return cyan
    case 'purple':
      return purple
    default:
      console.error(
        'Invalid color preset, accepted values: "blue", "green", "cyan", "indigo" or "purple"".'
      )
      return blue
  }
}
