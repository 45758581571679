import type { NeutralColors, PaletteColor } from '@mui/material/styles'
import { alpha } from '@mui/material/styles'

export type ColorObject = {
  [key: string]: string
}

const withAlphas = (color: PaletteColor): PaletteColor => {
  return {
    ...color,
    alpha4: alpha(color.main, 0.04),
    alpha8: alpha(color.main, 0.08),
    alpha12: alpha(color.main, 0.12),
    alpha20: alpha(color.main, 0.2),
    alpha30: alpha(color.main, 0.3),
    alpha50: alpha(color.main, 0.5),
  }
}

export const neutral: NeutralColors = {
  50: '#F8F9FA',
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D2D6DB',
  400: '#9DA4AE',
  500: '#6C737F',
  600: '#4D5761',
  700: '#2F3746',
  800: '#1C2536',
  900: '#111927',
}

export const nabuColors: ColorObject = {
  ambre: '#E67E22',
  brown: '#663C00',
  blackColor: '#000000',
  blackColor2: '#111111',
  blackColor3: '#111927',
  blackOpacity: '#ff000011',
  blueAzure: '#1976d2',
  blueBarbelLight: '#8193D1',
  blueDark: '#123456',
  blueDarkGrey: '#2F3746',
  blueMajorelle: '#004EEB',
  bluePastel: '#789abc',
  blueViolet: '#11232761',
  brownVelvet: '#F044381A',
  greenCyan: '#2CA398',
  greenCyan2: '#06B7B2',
  greenDark: '#1119271F',
  greenEmeraude: '#18b464',
  greenPine: '#217A72',
  grey: '#D3D3D3',
  greyConcrete: '#F2F4F7',
  greyIron: '#C4C4C4',
  greySteel: '#AAAAAA',
  greyColorLight: '#F4F4F4',
  greyColorLight2: '#EEEEEE',
  greyColorLight3: '#9DA0A6',
  greyBorder: '#555555',
  greyBorder2: '#6C737F',
  greyBorder3: '#333333',
  greyColorDark: '#999999',
  greyColorDarkest: '#666666',
  greyColorDarkest2: '#777777',
  orange: '#F79009',
  red: '#F70D1A',
  red2: '#ed6c02',
  redMadderColor: '#E74C3C',
  redStrawberry: '#D32F2F',
  redBrick: '#f5a14211',
  turquoise: '#C0E0DC',
  violetLight: '#f5a14288',
  whiteColor: '#FFFFFF',
  yellowLight: '#FFF4E5',
}

export const blue = withAlphas({
  lightest: '#F5F8FF',
  light: '#EBEFFF',
  main: '#2970FF',
  dark: '#004EEB',
  darkest: '#00359E',
  contrastText: '#FFFFFF',
})

export const green = withAlphas({
  lightest: '#F6FEF9',
  light: '#EDFCF2',
  main: '#16B364',
  dark: '#087443',
  darkest: '#084C2E',
  contrastText: '#FFFFFF',
})

export const cyan = withAlphas({
  lightest: '#87E0D8',
  light: '#5FD6CB',
  main: '#2CA398',
  dark: '#217A72',
  darkest: '#16514C',
  contrastText: '#FFFFFF',
})

export const indigo = withAlphas({
  lightest: '#F5F7FF',
  light: '#EBEEFE',
  main: '#2CA398',
  dark: '#268E85',
  darkest: '#312E81',
  contrastText: '#FFFFFF',
})

export const purple = withAlphas({
  lightest: '#F9F5FF',
  light: '#F4EBFF',
  main: '#9E77ED',
  dark: '#6941C6',
  darkest: '#42307D',
  contrastText: '#FFFFFF',
})

export const success = withAlphas({
  lightest: '#F0FDF9',
  light: '#3FC79A',
  main: '#10B981',
  dark: '#0B815A',
  darkest: '#134E48',
  contrastText: '#FFFFFF',
})

export const info = withAlphas({
  lightest: '#ECFDFF',
  light: '#CFF9FE',
  main: '#06AED4',
  dark: '#0E7090',
  darkest: '#164C63',
  contrastText: '#FFFFFF',
})

export const warning = withAlphas({
  lightest: '#FFFAEB',
  light: '#FEF0C7',
  main: '#F9E9DB',
  dark: '#f3d6bc',
  darkest: '#ebb88b',
  contrastText: '#000',
})

export const error = withAlphas({
  lightest: '#FEF3F2',
  light: '#FEE4E2',
  main: '#F04438',
  dark: '#B42318',
  darkest: '#7A271A',
  contrastText: '#FFFFFF',
})
